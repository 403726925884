.autocard.swiper-slide {
  .news-card-v2 {
    height: 100%;
    .no-touch,
    &:hover {
      .img-wrap .bg-image {
        transform: none !important;
        &:before {
          content: none !important;
        }
      }
      .caption .spot {
        color: $color-white;
      }
    }

    .content {
      border: 1px solid white;
      padding: 8px 25px;
      right: 15px;
      position: absolute;
      bottom: 15px;
      border-radius: 50px;
      i {
        margin-left: 5px;
      }
    }

    figure {
      height: 100%;
    }
  }
}