.iframe-large-container {
    width: 300px;
    height: 251px;
    overflow: hidden;
    background-color: #000810;
    .iframe-programImage {
        float: left;
        width: 159px;
        height: 241px;
        opacity: 0.5;
        margin-top: 10px;
    }
    .iframe-program-title {
        float: left;
        width: 175px;
        height: 52px;
        color: #ffffff;
        font-family: Muli;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        margin-left: 107px;
        margin-top: 95px;
        margin-bottom: 104px;
        position: absolute;
    }
    .iframe-logo {
        float: left;
        width: 72px;
        height: 36px;
        margin-left: 57px;
        margin-top: 9px;
        position: absolute;
    }
    .iframe-songDetails {
        .iframe-songImage {
            width: 59px;
            height: 60px;
            border-radius: 7px;
            float: left;
            position: absolute;
            margin-left: 13px;
            margin-top: 173px;
        }
        .iframe-songName {
            float: left;
            position: absolute;
            width: 205px;
            height: 19px;
            color: #ffffff;
            font-family: Muli;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            margin-top: 173px;
            margin-left: 77px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .iframe-radyodPlayer {
            #radyodPlayer {
                .video-js {
                    outline: none;
                    position: absolute;
                    height: 41px;
                    width: 211px;
                    bottom: 0;
                    left: 0;
                    margin: 0px 0 28px 78px;
                    .vjs-play-control {
                        height: 41px;
                        width: 41px;
                        &::before {
                            padding: 5px 0;
                        }
                    }
                }
            }

            .radyod-player-wrapper {
                position: absolute;
                margin-top: 188px;
                margin-left: 75px;
            }
        }
    }
}

.iframe-small-container {
    width: 345px;
    height: 65px;
    overflow: hidden;
    border-radius: 7px;
    background-color: #000810;
    .iframe-logo {
        float: left;
        margin-top: 15px;
        padding: 3px;
        padding-right: 6px;
        height: 30px;
        width: 61px;
    }
    .iframe-program-title {
        float: left;
        margin-top: 15px;
        width: 230px;
        height: 20px;
        color: #ffffff;
        font-family: Muli;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .iframe-song-name {
        float: left;
        width: 230px;
        height: 19px;
        color: #ffffff;
        font-family: Muli;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin-top: -5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    #radyodPlayer {
        .video-js {
            height: 45px;
            width: 45px;
            margin-top: 3px;
            .vjs-play-control {
                height: 41px;
                width: 41px;
                &::before {
                    padding: 5px 0;
                }
            }
        }
    }

    .radyod-player-wrapper {
        position: absolute;
        margin-left: 290px;
        margin-top: 12px;
    }
}
