html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
*:before,
*:after {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  vertical-align: baseline;
  text-decoration: none;
  font-style: normal;
}

html {
  -webkit-text-size-adjust: 100%;
  touch-action: manipulation;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

@include media-breakpoint-up(sm) {
  a:active,
  a:hover {
    outline: 0;
    text-decoration: none;
  }
}

:focus {
  outline: 0;
}

strong {
  font-weight: bold;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote {
  &:before,
  &:after {
    content: '';
    content: none;
  }
}

q {
  &:before,
  &:after {
    content: '';
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: $color-white;
  text-decoration: none;
}

img {
  max-width: 100%;
}

.cf {
  *zoom: 1;

  &:before {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
    content: ' ';
    display: table;
  }
}
input {
  box-sizing: border-box;
}

textarea {
  box-sizing: border-box;
  resize: none;
}
textarea,
input,
button,
select {
  font-family: inherit;
}

[role='button'],
input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  box-sizing: content-box;
}

input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  outline: none;
  overflow: visible;
  padding: 0;
  user-select: none; /* for button */
  -webkit-appearance: button; /* for input */
  cursor: pointer;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Make `a` like a button */
[role='button'] {
  color: inherit;
  cursor: default;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  user-select: none;
  white-space: pre;
}

/* remove close button from search input */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  appearance: none;
  display: none;
}

/* iframe ve ymaps css */
iframe {
  max-width: 100% !important;
}

.hidden {
  display: none !important;
}
