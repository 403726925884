.listing-holder {
  padding: 24px 30px 0;
  @include media-breakpoint-up(lg) {
    padding: 0;
  }
  .cat-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 14px;
  }

  .img-wrap {
    @include media-breakpoint-down(sm) {
      width: 141px !important;
      padding-bottom: 25%;
    }
  }
}
