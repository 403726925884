.default-card {
  $self: &;
  display: block;

  .figure {
    position: relative;
    .hover-text {
      display: none;
    }

    .img-wrap {
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(3, 19, 36, 0.8);
        z-index: 1;
        content: '';
        opacity: 0;
        transition: opacity 0.2s ease-out;
        will-change: opacity;
      }
    }

    img {
      display: block;
      transition: transform 0.2s ease-out;
      width: 100%;
      will-change: transform;
    }

    .ico {
      color: $color-white;
      font-size: 44px;
      height: 40px;
      left: 50%;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 0.2s ease-out;
      width: 52px;
      will-change: opacity;
      @extend .fas;
      @extend .fa-images;
    }

    .flag-dark {
      bottom: 4px;
      position: absolute;
      right: 4px;
    }

    .caption {
      position: relative;
    }

    .title {
      font-size: 14px;
      margin-top: 5px;
      font-weight: 700;
      color: rgba($color-white, 0.6);
    }

    .spot {
      font-size: 12px;
      color: rgba($color-white, 0.6);
      font-weight: 800;
      margin-bottom: 0;
      line-height: 17px;
      a {
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        color: #0591e2;
        @include media-breakpoint-up(lg) {
          display: none;
        }
        i {
          margin-left: 2px;
        }
      }

      &.spot-lg {
        font-size: 14px;
        margin-top: 5px;
      }
    }

    @include media-breakpoint-up(sm) {
      .no-touch,
      &:hover {
        .img-wrap {
          &:before {
            opacity: 1;
          }
        }
        .title,
        .spot {
          color: $color-white;
        }

        .hover-text {
          position: absolute;
          display: none;
          justify-content: center;
          transition-duration: 0.2s;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          z-index: 2;
          @include media-breakpoint-up(lg) {
            display: flex;
          }
        }
      }
    }

    &.mobile-horizontal {
      .figure {
        @include media-breakpoint-down(sm) {
          display: flex;
        }
      }
      .img-wrap {
        @include media-breakpoint-down(sm) {
          width: 142px;
          flex-shrink: 0;
          align-self: flex-start;
        }
      }
      .caption {
        @include media-breakpoint-down(sm) {
          margin-left: 8px;
          align-self: center;
          flex-grow: 1;
        }
      }
      .title {
        @include media-breakpoint-down(sm) {
          margin-top: 0;
        }
      }
    }
  }
}
