.loading-container {
  display: flex;
  margin: 15px auto;
  justify-content: center;
  vertical-align: middle;

  .bar {
    width: 6px;
    height: 30px;
    margin: 3px;
    display: inline-block;
    transform-origin: center;
    animation: loading 1s ease-in-out infinite;

    &.bar1 {
      animation-delay: 0.1s;
    }

    &.bar2 {
      animation-delay: 0.2s;
    }

    &.bar3 {
      animation-delay: 0.3s;
    }

    &.bar4 {
      animation-delay: 0.2s;
    }

    &.bar5 {
      animation-delay: 0.1s;
    }
  }

  @keyframes loading {
    0% {
      transform: scaleY(0.1);
    }

    50% {
      transform: scaleY(1);
      background: $color-fb;
    }

    100% {
      transform: scaleY(0.1);
      background: transparent;
    }
  }
}
