@import '../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';

html {
  font-size: 10px;
}

body {
  color: $color-white;
  background-color: $color-black-pearl2;
  font-family: 'Muli', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  min-height: 100%;
  padding: 0;
  position: relative;
  -webkit-text-size-adjust: 100%;
  transition: opacity 0.2s linear;
  background: $color-black-pearl2;
}

main {
  padding-bottom: 110px;
  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }
  .left-content {
    @include media-breakpoint-up(lg) {
      padding: 10px 40px 0 350px;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 480px;
    }
    @include media-breakpoint-down(md){
      padding: 0;
      margin-top: -20px;
    }
    > div {
      max-width: 1160px;
      margin: 0 auto;
    }
  }
}

.section {
  margin-bottom: 40px;
  @include media-breakpoint-up(lg) {
    margin-bottom: 60px;
  }

  &.dark {
    background-color: $color-blue-charcoal2;
  }
  &.regular {
    background-color: $color-blue-charcoal;
  }
  &.fluid {
    .container {
      @include media-breakpoint-down(lg) {
        max-width: none;
      }
    }
  }

  &-title {
    color: $color-white;
    font-size: 14px;
    font-weight: 900;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 17px;
    }
  }

  // for multiple items (title + select..)
  &-header {
    align-items: center;
    display: flex;
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 17px;
    }
    .section-title {
      margin-bottom: 0;
    }
    .listing-select {
      margin-left: auto;
    }
  }

  &-top-series {
    position: relative;
    .section-top-link {
      position: absolute;
      float: right;
      right: 30px;
      color: $color-cerulean;
      margin-top: -32px;
      font-size: 14px;
      font-weight: 700;
      @include media-breakpoint-up(lg) {
        right: 12px;
        margin-top: -40px;
      }
    }

    .item-row {
      padding-bottom: 15px;
      flex-wrap: nowrap;
      -webkit-overflow-scrolling: touch;
      overflow-x: auto;
      overflow-y: hidden;
      max-width: 100%;
      &::-webkit-scrollbar {
        display: none;
      }

      @include media-breakpoint-up(lg) {
        flex-wrap: wrap;
      }

      > div {
        flex-shrink: 0;
        max-width: 220px;
        flex-basis: 45%;
        .caption {
          bottom: -15px;
        }
        .title {
          font-size: 14px;
          line-height: 20px;
        }
        .spot {
          font-size: 12px;
          line-height: 18px;
        }
        .number {
          font-size: 14px;
          height: 31px;
          width: 31px;
        }

        @include media-breakpoint-up(lg) {
          max-width: 20%;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .no-touch,
    &:hover {
      .swiper-pagination {
        opacity: 1;
      }

      .swiper-navigation-prev:not(.swiper-button-disabled),
      .swiper-navigation-next:not(.swiper-button-disabled) {
        opacity: 1;
      }
    }
  }

  &.socialmedia-section {
    margin-bottom: 40px;

    @include media-breakpoint-only(md) {
      padding-right: 42px;
      padding-left: 42px;
    }
    
    .swiper-container {
      margin-bottom: 30px;
      padding-bottom: 30px;
    }
    .swiper-wrapper .swiper-slide {
      width: 100% !important;

      .bg-image {
        height: 168px;

        img {
          width: 100%;
        }
      } 
    }
    
    .swiper-pagination {
      top: 220px !important;
      text-align: center;
      @include media-breakpoint-down(md) {
        top: 240px !important;
        opacity: 1;
      }
      @include media-breakpoint-down(sm) {
        top: 330px !important;
      }

      @include media-breakpoint-up(xl) {
        top: 221px !important;
      }

      @media only screen and (min-width: 1550px) {
        top: 260px !important;
      }
    }

    @include media-breakpoint-down(md) {
      .news-swiper .swiper-container .swiper-slide {
        max-width: 100%
      }
    }
  }
}

.native-swiper {
  .swiper-pagination,
  .swiper-navigation-prev,
  .swiper-navigation-next {
    display: none;
  }

  .swiper-container {
    margin: 0 -10px;
  }

  .swiper-wrapper {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .swiper-slide {
    max-width: 250px;
    padding-left: 10px;
    padding-right: 10px;
    @include media-breakpoint-up(md) {
      max-width: 220px;
    }
  }
}

.flag-light {
  background-color: $color-white;
  border-radius: 10px;
  color: $color-tory-blue;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  line-height: 18px;
  padding: 0 7px;

  i {
    font-size: 9px;
    margin-right: 2px;
  }
}

.flag-dark {
  background-color: rgba($color-black, 0.6);
  border-radius: 5px;
  color: $color-silver;
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  padding: 0 3px 0 5px;

  i {
    font-size: 9px;
    margin-right: 2px;
  }
}

//for uKit
.ui-button {
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 14px;
  color: $color-white;
  font-family: Muli;
  font-size: 14px;
  font-weight: 700;
  transition-duration: 0.2s;

  &-sm {
    padding: 8px;
    font-size: 12px;
    font-weight: 600;
  }

  &-lg {
    padding: 12px;
    font-size: 16px;
    font-weight: 800;
  }

  @include media-breakpoint-up(sm) {
    &:hover,
    &.active {
      box-shadow: 0 3px 30px rgba(13, 119, 209, 0.58);
      background-image: linear-gradient(114deg, $color-cerulean2 0%, $color-denim 100%);
      border-color: transparent;
      transition-duration: 0.2s;
    }
  }

  &.outline {
    border: 1px solid $color-white;

    @include media-breakpoint-up(sm) {
      &:hover,
      &.active {
        border-color: transparent;
      }
    }
  }

  &.primary {
    border-color: transparent;
    padding: 8px 25px 11px 25px;
    background-image: linear-gradient(105deg, $color-cerulean2 0%, $color-denim 100%);
  }

  &.square {
    border-color: transparent;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    background-color: $color-blue-zodiac;
    @include media-breakpoint-up(sm) {
      &:hover &.active {
        border-radius: 7px;
      }
    }
  }

  &.label {
    color: $color-cerulean;
    transition-duration: 0.2s;
    border-width: 0;
    @include media-breakpoint-up(sm) {
      &:hover,
      &.active {
        box-shadow: none;
        transition-duration: 0.2s;
        background: transparent;
        color: $color-white;
      }
    }
  }
  &.share {
    opacity: 0.6;
    border: 0;
    @include media-breakpoint-up(sm) {
      &:hover,
      &.active {
        box-shadow: none;
        transition-duration: 0.2s;
        background: transparent;
        opacity: 1;
      }
    }
  }

  &.icon {
    border: 2px solid $color-white;
    font-size: 20px;
    &-play {
      @extend .fas, .fa-play;
    }
    &-stop {
      @extend .fa, .fa-stop;
    }
    @include media-breakpoint-up(sm) {
      &:hover,
      &.active {
        color: $color-cerulean;
        border-color: $color-cerulean;
        box-shadow: none;
        transition-duration: 0.2s;
        background: transparent;
      }
    }
  }

  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
    background-color: rgba($color: #000000, $alpha: 0.2);
    @include media-breakpoint-up(sm) {
      &:hover {
        box-shadow: unset;
      }
    }
  }

  i {
    padding-left: 5px;
  }
}

.five-grid {
  @include media-breakpoint-up(md) {
    flex-basis: 20%;
    // &:nth-child(1n + 3) {
    //   flex-basis: 20%;
    // }
  }
}

//Toast Start
.toast {
  z-index: 10112;
  left: 50%;
  margin: 0 auto;
  position: fixed;
  transform: translateX(-50%);
  font-size: 18px;
  padding: 10px 15px;
  border-radius: 7px;

  &.default {
    background: $color-denim;
  }
  &.success {
    background: $color-caribbean-green;
  }
  &.danger {
    background: $color-dusty-gray;
  }
  &.error {
    background: $color-cinnabar;
  }

  //Toast için eksik implementation //
  // &.bottomCenter {
  // }
  // &.topCenter {
  // }
  // &.bottomLeft {
  // }
  // &.topLeft {
  // }
  // &.bottomRight {
  // }
  // &.topRight {
  // }
}
//Toast end

//Pagination Start

.pagination-container {
  padding: 0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: center;

  .pagination-box {
    float: left;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    height: 34px;
    width: 34px;
    margin: 0 6px;
    background-color: #0e2c46;
    border-radius: 4px;

    &.not-number {
      cursor: default;
    }
    &.active {
      background-color: #0591e2;
    }

    span {
      margin-top: 7px;
    }
  }
}

//Pagination End

.see-all-card {
  border-radius: 7px;
  background-color: $color-blue-zodiac;
  cursor: pointer;
  display: flex;
  justify-content: center;
  span {
    max-height: 218px;
    font-size: 14px;
    font-weight: 700;
    opacity: 0.6;
    padding: 25% 0;
    @include media-breakpoint-up(md) {
    }
  }
  @include media-breakpoint-up(sm) {
    &:hover {
      span {
        opacity: 1;
      }
    }
  }

  &.new {
    min-height: 125px;
    margin: 15px 0 !important;
    align-items: center;
    span {
      padding: 0 !important;
      max-height: none !important;
    }

    @include media-breakpoint-down(md) {
      min-height: 124px;
      margin: 22px 0 !important;
    }
  }
}

.notfound-container {
  background-image: url(../img/notfound_background.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  text-align: center;
  span:nth-child(1) {
    display: block;
    color: #0591E2;
    font-size: 20vw;
    font-weight: 900;
    // letter-spacing: 34.45px;
    // line-height: 300px;
    @include media-breakpoint-down(md) {
      font-size: 30vw;
    }
  }
  span:nth-child(2) {
    display: block;
    margin: 20px auto;
    padding-bottom: 200px;
    font-size: 30px;
    font-weight: 900;
    opacity: 0.45;
    @include media-breakpoint-down(md) {
      padding-bottom: 50px;
    }
  }
}

.manset-loading-image{
  margin-bottom: 60px;
  width: 100%;
  height: 142px;
  @include media-breakpoint-up(md) {
    height: 464px;
  }
}

.program-section,
.podcast-section,
.news-section {

  @include media-breakpoint-down(md) {
    padding-right: 42px;
    padding-left: 42px;
    overflow: hidden;
    
    .swiper-container {
      overflow: inherit;
    }
  }

  @include media-breakpoint-down(sm) {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.section-top-series {

  @include media-breakpoint-down(md) {
    padding-right: 42px;
    padding-left: 42px;

    .item-row {
      max-width: inherit;
      padding-right: 30px;
      padding-left: 30px;
      margin-right: -42px;
      margin-left: -42px;
    }
  }

  @include media-breakpoint-down(sm) {
    padding-right: 30px;
    padding-left: 30px;

    .item-row {
      padding-right: 20px;
      padding-left: 20px;
      margin-right: -30px;
      margin-left: -30px;
    }
  }
}