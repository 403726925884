
/* The Modal (background) */
.modal-container {

.lightbox-modal {
    display: none; 
    position: fixed;
    z-index: 99999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: rgba(3, 19, 36, 0.7);
    backdrop-filter: blur(10px);

  &-close-btn {
    background: $color-white;
    border-radius: 50%;
    cursor: pointer;
    height: 32px;
    position: absolute;
    right: 20px;
    top: 20px;
    transition: opacity 0.3s linear;
    width: 32px;
    z-index: 11;
    &:after,
    &:before {
      background-color: $color-black;
      content: ' ';
      height: 24px;
      top: 5px;
      left: 15px;
      position: absolute;
      transform: rotate(45deg);
      width: 2px;
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  /* Modal Content */
  .modal-content {
    background-color: $color-black-pearl2;
    margin: auto;
    padding: 20px;
    width: 100%;
    border-radius: 10px;

    
    //background-image: url(/img/lightbox-bg.svg);
    @include media-breakpoint-up(sm) {
      max-width: 680px;
    }
    .player {
      position: absolute;
      top:0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
    .img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%)
    }
    span, img {
      cursor: pointer;
    }
    span{
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: left;
    }
    img {
      height: auto;
      width: auto;
    }
    .modal-footer {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .social-list {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-left: 30px;

        li {
          height: auto;
          font-size: 20px;
          padding: 0px 5px;
          font-size: 25px;
        }
    }
    }
  }
  .modal-inner {
    position: relative;
      &:before {
        content: "";
        display: block;
        height: 0px;
        padding-top: (9 / 16) * 100%;
        width: 100%;
      }
  }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  
}
.show {
    display: flex;
  }
  .hide {
    display: none;
  }
}