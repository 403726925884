.share-popup {
  align-items: center;
  background: rgba(3, 19, 36, 0.7);
  backdrop-filter: blur(10px);
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10111;
  &.open {
    display: flex;
  }
  &-close-btn {
    background: $color-white;
    border-radius: 50%;
    cursor: pointer;
    height: 32px;
    position: absolute;
    right: 20px;
    top: 20px;
    transition: opacity 0.3s linear;
    width: 32px;
    z-index: 11;
    &:after,
    &:before {
      background-color: $color-black;
      content: ' ';
      height: 24px;
      top: 5px;
      left: 15px;
      position: absolute;
      transform: rotate(45deg);
      width: 2px;
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &-container {
    background-color: $color-black;
    border-radius: 10px;
    max-width: 345px;
    padding: 30px;
    color:$color-white;
    .title {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 15px;
    }
    .copylink {
      border: 1px solid rgba($color-white, 0.2);
      border-radius: 4px;
      padding: 0 10px;
      height:56px;
      overflow: hidden;
      display: flex;
      > div{
        display: flex;
        position: relative;
        &:after {
          content: '';
          background-image: linear-gradient(to left, rgba($color-black, 0.9) 30%, rgba($color-black, 0) 100%);
          position: absolute;
          right: 0;
          top: 0;
          width: 20px;
          height: 100%;
        }
      }
      .share-url {
        font-size: 14px;
        position: relative;
        color: rgba($color-white, 0.3);
        border: none;
        background: none;
      }
      button {
        font-size: 12px;
        white-space: nowrap;
        i{
          padding:0 1px 0 5px;
        }
      }
    }
  }
  &-btns {
    display: flex;
    list-style: none;
    margin: 0 0 30px 0;
    justify-content: space-between;
    li {
      padding: 0;
      width: 122px;
      a {
        background-color: rgba(#0591e2, 0.1);
        border-radius: 6px;
        display: block;
        font-size: 11px;
        padding: 12px 0 8px;
        text-align: center;
        transition: background-color 0.2s linear;
        position: relative;
        width:100%;
        height: 75px;
        @include media-breakpoint-up(sm) {
           &:hover {
          background-color: rgba(#0591e2, 0.2);
        }
        }
      }
    }
    i {
      display: block;
      font-size: 26px;
      margin-bottom: 10px;
    }

    & + h2 {
      margin-top: 45px;
    }
  }
}
