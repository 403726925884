header {
  height: 60px;
  left: 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10000;
  margin-bottom: 20px;

  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    padding: 0;
    position: relative;
    position: sticky;
    z-index: 1113;
    margin-bottom: 0;
    &.blur {
      .navbar {
        position: relative;
        background: transparent;
        overflow: hidden;
        &:before {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          content: '';
          background: rgba(3, 19, 36, 0.7);
          backdrop-filter: blur(25px);
        }
      }
    }
  }

  .header-area {
    padding: 0;

    &-wrapper {
      overflow: hidden;
      padding: 7px 5px;
      background: $color-black-caparol;
      display: flex;
      @include media-breakpoint-up(lg) {
        max-width: 310px;
      }
      @include media-breakpoint-up(xl) {
        max-width: 440px;
      }
    }

    .logo {
      float: left;
      line-height: 1;
    }

    .frequency {
      float: right;
      margin-left: auto;
      color: rgba($color-white, 0.6);
      &-item {
        font-size: 11px;
        float: left;
        font-weight: 700;
        padding: 0 5px;
        span {
          font-weight: 900;
        }
        @include media-breakpoint-up(md) {
          padding: 0 10px;
        }        
        span {
          font-size: 20px;
          display: block;
        }
      }
    }
    
    .whatsapp {
      float: right;
      color: rgba($color-white, 0.6);
      &-item {
        font-size: 11px;
        float: left;
        font-weight: 700;
        padding: 0 5px;
        border-left: 1px solid hsla(0,0%,100%,.1);
        span {
          font-weight: 900;
        }
        @include media-breakpoint-up(md) {
          padding: 0 10px;
        }        
        span {
          font-size: 14px;
          padding: 5px 0 0;
          display: block;
        }
      }
    }

    .search-button {
      display: flex;
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    }
    .search-button {
      cursor: pointer;
      font-size: 22px;
      opacity: .8;
      margin: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      display: none;
  }

  .navbar {
    background-image: linear-gradient(to top, #002038 0%, rgba(26, 50, 70, 0.65) 100%);
    bottom: 0;
    display: flex;
    height: 44px;
    left: 0;
    position: fixed;
    backdrop-filter: blur(10px);
    padding: 0;
    @include media-breakpoint-up(lg) {
      position: static;
      height: 100%;
      background: $color-black-pearl2;
      padding-left: 30px;
      padding-right: 30px;
      flex: 1;
      max-width: calc(100% - 310px);
    }
    @include media-breakpoint-up(xl) {
      max-width: calc(100% - 440px);
     }
  }

  .main-nav {
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 100%;
    width: 100%;

    @include media-breakpoint-up(lg) {
      overflow-x: hidden;
      max-width: 1180px;
      margin: 0 auto;
    }

    .search-button {
      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }
    .to-top-btn {
      background: transparent url(../img/to-top-button.png) no-repeat;
      background-position: center;
      background-size: 14px;
      flex: 0 0 auto;
      opacity: .8;
      flex-grow: 1;
      height: 22px;
      width: 22px;
      padding: 22px;
      z-index: 999999;
      @include media-breakpoint-up(md) {
        background-size: 18px 18px;
        height: 26px;
        width: 26px;
      }
      @include media-breakpoint-up(lg) {
        display: none;
      }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url(../img/to-top-button@2x.png);
      }
      @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
        background-image: url(../img/to-top-button@3x.png);
      }
    }
  }

  .main-nav-list {
    display: flex;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    &::-webkit-scrollbar {
      background: transparent;
      height: 0;
      width: 0;
    }
    @include media-breakpoint-up(md) {
      padding: 0 24px;
    }
    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
      padding: 0;
    }

    .main-nav-item {

      padding: 0 12px;
      @include media-breakpoint-up(lg) {
        padding: 0 30px 0 0;
        &:last-child {
          padding: 0;
        }
      }

      .main-nav-link {
        align-items: center;
        color: rgba($color-white, 0.6);
        display: flex;
        font-size: 12px;
        font-weight: 700;
        height: 44px;
        letter-spacing: 0.84px;
        text-shadow: 0 2px 10px rgba($color-black, 0.16);
        white-space: nowrap;
        position: relative;

        svg {
          fill: rgba($color-white, 0.6);
        }

        img {
          margin-right: 10px;
          margin-left: 5px;
        }
        @include media-breakpoint-up(md) {
          font-size: 15px;
          letter-spacing: 1px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 14px;
          height: 40px;
          padding: 0;
          margin: 10px 10px;

          img {
            margin-right: 0;
          }
        }

        @include media-breakpoint-up(sm) {
          &:hover {
            color: rgba($color-white, 1);

            svg {
              fill: rgba($color-white, 1);
            }
            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                height: 3px;
                width: 100%;
                background-color: #0591e2;
              }
          }
        }

          &.active {
            color: rgba($color-white, 1);
            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                height: 3px;
                width: 100%;
                background-color: #0591e2;
              }
          }

      }
    }
  }

  .end {
    &:before {
      content: '';
      width: 65px;
      height: 100%;
      position: absolute;
      right: 0;
      background-image: linear-gradient(to left, rgba($color-midnight, 0.95) 0%, rgba($color-midnight-blue, 0.1) 100%);
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}
