.radyod-player-wrapper {
    margin: 5px;
    z-index: 100;

    .player-element-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 16px;

        .play-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            min-width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px solid white;
            font-size: 15px;
            cursor: pointer;
        }

        .mute-button {
            border-radius: 50%;
            padding: 5px 0;
            font-size: 20px;
            min-width: 35px;
            cursor: pointer;
        }

        .volume-bar {
            width: 100%;
            cursor: pointer;
        }

        .volume-element {
            display: flex;
            flex-direction: column;
            width: 35px;
            cursor: pointer;

            .volume-bar {
                height: 100px;
                display: flex;
                justify-content: center;

                &.vertical {
                    writing-mode: bt-lr;
                    -webkit-appearance: slider-vertical;
                }
            }

            &.active {
                margin-top: -104px;
            }
        }

        .progress-bar-wrapper {
            display: flex;
            flex-direction: row;
            gap: 16px;
            width: 100%;
            font-size: 12px;

            @media screen and (min-width:768px) {
                font-size: 14px;
            }

            .current-with-duration {
                display: flex;
                justify-content: center;
                flex: 0 0 auto;
                min-width: 90px;
            }

            .progress-bar {
                width: 100%;
                cursor: pointer;
            }
        }
    }

    .radyod-player {
        display: none;
    }

    &.sm {
        .player-element-wrapper {
            .play-button {
                padding: 5px 7.5px;
                font-size: 10px;
                cursor: pointer;
            }
        }

        .mute-button {
            border-radius: 50%;
            padding: 7.5px;
            font-size: 15px;
            min-width: 25px;
            cursor: pointer;
        }

        .progress-bar-wrapper {
            font-size: 10px;
            .current-with-duration {
                min-width: 75px;
            }
        }
    }

    &.lg {
        .player-element-wrapper {
            .play-button {
                cursor: pointer;
                font-size: 20px;
                min-width: 54px;
                height: 54px;
                padding: 0;
                border-radius: 50%;
                border: 2px solid white;
            }
        }
    }
}