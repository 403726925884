.listing {
	&-holder {
		@include media-breakpoint-up(md){
			padding-bottom: 4px;
		}
		.item {
			margin-bottom: 24px;
			@include media-breakpoint-up(md){
				margin-bottom: 44px;
			}
		}
	}
}
