.vectormap-container {
    margin: 20px auto;
    width: 100%;

    svg {
        width: 100%;

        g {
            fill: #0F2C46;
            &:hover {
                fill: #fff;
            }
        }
    }

}