.top20-container {
  padding: 24px 30px 0;
  @include media-breakpoint-up(lg) {
    padding: 0;
  }
  .five-grid {
    margin-bottom: 35px;
    &:first-child {
      @include media-breakpoint-down(sm) {
        height: 394px;

        .img-wrap {
          @include media-breakpoint-down(sm) {
            height: 394px;
          }

        }
      }
    }
  }

  .summary-text {
    font-size: 14px;

    .cat-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 14px;
    }

    .desc {
      color: rgba($color-white, 0.6);
      line-height: 25px;
      margin-bottom: 5px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 25px;
      }
    }
  }

  .top20-list {
    margin-right: -30px;
    margin-left: -30px;
    @include media-breakpoint-up(md) {
      margin-right: 0;
      margin-left: 0;
    }
    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding-right: 30px;
      padding-left: 30px;
      margin-bottom: 16px;
      background: $color-black-caparol;
      overflow: hidden;
      @include media-breakpoint-up(md) {
        padding-right: 0;
        padding-left: 0;
        margin-bottom: 24px;
        border-radius: 10px;
      }
      img {
        width: 72px;
        height: 72px;
        border-radius: 7px;
        float: left;
      }

      .caption {
        display: flex;
        align-items: center;
        flex-grow: 1;
        float: left;
        padding: 0 18px;
        color: rgba($color-white, 0.6);

        @include media-breakpoint-up(lg) {
          padding: 6px 26px;
        }

        .number {
          font-size: 18px;
          font-weight: 700;
          color: $color-cerulean;
          padding: 0;
          width: 40px;

          @include media-breakpoint-up(lg) {
            font-size: 24px;
            width: 70px;
          }
        }

        .song-description {
          .artist-name {
            font-size: 14px;
            font-weight: 700;
            text-overflow: ellipsis;
          }
          .song-name {
            font-size: 12px;
            font-weight: 500;
            display: block;
            font-weight: normal;
          }
        }
        
      }

      .top20-player-wrapper {
        display: flex;
        justify-content: flex-end;
        padding-top: 5px;
        padding-bottom: 5px;
        margin: 0;
        @include media-breakpoint-up(md) {
          padding-right: 24px;
        }
      }

      .play-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        padding: 0 !important;
      }
    }

    .player-button {
      opacity: .6;
      float: right;
      margin: 10px;
      border-radius: 50%;
      border: 1px solid #fff;
      padding: 8px;
      width: 31px;
      text-align: center;
      
      &:hover {
        opacity: 1;
      }

      &.play {
        opacity: 1;
      }

      &.pause {}
    }
  }
  
  .section-top-series {
    padding: 0;
  }
}