.podcast-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  height: 235px;
  color: $color-white;
  // background: linear-gradient(
  //   100deg,
  //   rgba(84, 19, 111, 0) 0%,
  //   rgba(54, 6, 65, 0.850577731092437) 40%,
  //   rgba(15, 9, 66, 0.7497373949579832) 60%,
  //   rgba(17, 37, 80, 0) 100%
  // );
  background-image: url(../img/podcast-header-background-mobile.png);
  background-size: unset;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;

  @include media-breakpoint-up(lg) {
    height: 367px;
    background-image: url(../img/podcast-header-background.png);
  }

  .artist-img {
    width: 39%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    > img{
      max-height: 100%;
      margin-right: 10px;
      @include media-breakpoint-up(lg){
        margin-right: 20px;
      }
    }
  }
  .header-description {
    padding: 0;
    width: 61%;
    h2 {
      font-size: 18px;
      font-weight: 900;
      line-height: 25px;
      @include media-breakpoint-up(lg) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    h3 {
      font-size: 12px;
      font-weight: 700;
      line-height: 19px;
      @include media-breakpoint-up(lg) {
        font-size: 14px;
        line-height: 20px;
        margin-top: 8px;
      }
    }
    p {
      display: none;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-top: 14px;
      opacity: 0.6;
      @include media-breakpoint-up(md) {
        display: block;
      }
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0 0;
      padding: 0 12px;
      font-size: 12px;
      height:34px;
      white-space: nowrap;
      i {
        margin-right: 10px;
        font-size:10px;
      }
      @include media-breakpoint-up(md) {
        margin-top: 10px;
        font-size: 14px;
        height: 40px;
        padding: 0 28px;
      }
    }
  }
}

.collapse {
  margin-top: -15px;

  @include media-breakpoint-only(md) {
    padding: 0 42px;
  }

  .collapse-item {
    margin: 15px 0;
    display: block;
    overflow: hidden;
    background-color: $color-black-caparol;

    @include media-breakpoint-up(sm) {
      margin: 15px -10px;
    }

    @include media-breakpoint-up(md) {
      border-radius: 7px;
      margin: 15px 0;
    }

    .collapse-header {
      color: $color-white;
      font-family: Muli;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      padding: 16px 66px 16px 30px;
      position: relative;
      @include media-breakpoint-up(md){
        padding: 24px 62px 24px 24px;
      }
      @include media-breakpoint-up(lg){
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &:hover,
      &.active {
        opacity: 1;
        h3,
        p {
          opacity: 1;
        }
      }

      h3 {
        opacity: 0.6;
        font-weight: 800;
      }
      p {
        padding: 4px 0;
        font-weight: 400;
        opacity: 0.6;
      }
      span {
        font-size: 12px;
        line-height: 18px;
        color: $color-cerulean;
        opacity: 1;
        &.mobile {
          @include media-breakpoint-up(md) {
            display: none;
          }
        }
        &.desktop {
          @include media-breakpoint-up(md) {
            padding: 0 5px;
            float: right;
            display: block;
            margin: 1px 27px 0 0;
          }
          @include media-breakpoint-up(xs) {
            display: none;
          }
        }
      }
      i {
        font-size: 18px;
        opacity: 0.6;
        position: absolute;
        top:50%;
        right:30px;
        margin-top:-14px;

        @include media-breakpoint-up(lg) {
          right:14px;
          padding: 5px;
        }
      }
    }
    .collapse-panel {
      display: none;
      overflow: hidden;
      opacity: 0;
      .player {
        display: flex;
        justify-content: center;
        position: relative;
        flex-direction: row;
        height: 200px;

        .video-player {
          min-height: 200px;
          min-width: 100%;
        }
        .voice-player {
          height: 200px;
          min-width: 100%;
          position: relative;
          z-index: 9;
        }
        .voice-animation {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 8;
        }
        .podcast-player-wrapper {
          z-index: 10;
          position: absolute;
          bottom: 0;
          width: 100%;

          .radyod-player-wrapper {
            margin: 16px 30px;
  
            @include media-breakpoint-up(md) {
              margin-right: 24px;
              margin-left: 24px;
            }
          }
        }
      }
      &.show {
        display: block;
        opacity: 1;
      }
    }
    .footer {
      display: block;
      overflow: hidden;
      padding: 10px 15px;
      background-color: $color-blue-zodiac;
      &.video {
        background-color: $color-black-caparol;
      }

      button {
        &:nth-child(1) {
          float: left;
        }
        &:nth-child(2) {
          float: right;
        }
      }
    }
  }
}

.parallax > use {
  animation: move-forever 12s linear infinite;
  animation-play-state: paused;
  &:nth-child(1) {
    animation-delay: -2s;
    fill: rgba(14, 44, 70, 0.5);
  }
  &:nth-child(2) {
    animation-delay: -2s;
    animation-duration: 5s;
    fill: rgba(14, 44, 70, 0.6);
  }
  &:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 3s;
    fill: rgba(14, 44, 70, 0.6);
  }
}

@keyframes move-forever {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

.animation {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  margin: 0;
  &.active{
    .parallax > use {
      animation-play-state: running;
    }
  }
}
