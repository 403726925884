.video-page-container {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
        padding: 30px 0px;
    }

    .player-wrapper {
        .player {
            width: 100% !important;
            // height: 720px !important;
            margin: auto;
            // width: 67vw !important;
            height: calc(70vw * (9/16)) !important;

            @include media-breakpoint-down(md) {
                width: 100vw !important;
                height: calc(100vw * (9/16)) !important;
            }
        }
    }

    .collapse-item {
        margin: 10px 0;
        background-color: #000810;
        padding: 5px;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        .collapse-header {
            font-size: 18px;
            padding: 0 5px;
            cursor: pointer;
            display: flex;

            i {
                margin-left: auto;
                margin-top: 5px;
            }
        }

        .collapse-body {
            padding: 6px 3px;
            font-size: 14px;
        }
    }

    .other-videos {
        margin: 20px;
    }
}