.news-card-v2 {
  $self: &;
  display: block;

  .img-wrap {
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    .bg-image {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-size: cover;
      transition: transform 0.2s ease-out;
      width: 100%;
      will-change: transform;
      background-position: right;
    }
  }

  .ico {
    color: $color-white;
    font-size: 44px;
    height: 40px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s ease-out;
    width: 52px;
    will-change: opacity;
    @extend .far;
    @extend .fa-newspaper;

    &.video {
      @extend .fa;
      @extend .fa-play;
      border-radius: 50%;
      border: 2px solid #fff;
      height: 70px;
      width: 70px;
      display: flex;
      justify-content: center;
      font-size: 34px;
      vertical-align: middle;
      align-items: center;
      align-self: center;
    }
  }

  .flag-dark {
    bottom: 4px;
    position: absolute;
    right: 4px;
  }

  .caption {
    position: relative;
  }

  .title {
    font-size: 14px;
    margin-top: 5px;
    color: $color-white;
    font-weight: 700;
    @include media-breakpoint-up(md) {
      font-size: 15px;
    }
  }

  .spot {
    font-size: 14px;
    margin-top: 5px;
    color: rgba($color-white, 0.6);
    font-weight: 700;
    margin-bottom: 0;
    @include line-clamp(2);
  }

  @include media-breakpoint-up(sm) {
    .no-touch,
    &:hover {
      .img-wrap .bg-image {
        transform: scale($img-scale-size);
        &:before {
          background: rgba(3, 19, 36, 0.8);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          content: '';
        }
      }
      .ico {
        opacity: 0.75;
      }
      .caption .spot {
        color: $color-white;
      }
    }
  }

  .touch & {
    .ico {
      opacity: 0.75;
    }
  }

  &.mobile {
    display: flex;
    .img-wrap {
      height: 80px !important;
      width: 140px !important;
    }
  }

  &.mobile-horizontal {
    .figure {
      @include media-breakpoint-down(sm) {
        display: flex;
      }
    }
    .img-wrap {
      @include media-breakpoint-down(sm) {
        flex-shrink: 0;
        align-self: flex-start;
        margin-right: 5px;
        width: 100%;
      }
    }
    .caption {
      @include media-breakpoint-down(sm) {
        margin-left: 8px;
        align-self: center;
        flex-grow: 1;
      }
    }
    .title {
      @include media-breakpoint-down(sm) {
        margin-top: 0;
      }
    }
    .ico {
      @include media-breakpoint-down(sm) {
        font-size: 28px;
        width: 26px;
        height: 24px;
        display: none;
      }
    }
  }
}
