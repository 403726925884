.right-content {
  @include media-breakpoint-up(lg) {
    padding: 0 !important;
  }
}

.radio-widget {
  background: $color-black-caparol;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: block;
  height: 65px;
  bottom: 48px;
  left: 15px;
  position: fixed;
  z-index: 1112;
  padding: 14px 18px;
  width: calc(100% - 30px);
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    left: inherit;
    bottom: inherit;
    top: 60px;
    width: 310px;
    position: fixed;
    height: calc(100% - 60px);
    border: none;
    background-size: 80%;
    background-position: -55px 25px;
  }
  @include media-breakpoint-up(xl) {
    width: 440px;
    background-size: 100%;
  }

  &.small {
    .play-button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid white;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      position: absolute;
      right: 55px;
      top: auto;
      z-index: 10;

      i {
        font-size: 14px !important;
      }
    }
  }

  .radioBg {
    position: absolute;
    left: 2%;
    top: 0;
    width: 96%;
    height: 100%;
    background-position: -55px 25px;
    opacity: 0.5;
    background-repeat: no-repeat;
    display: none;
    max-height: 520px;
    @include media-breakpoint-up(lg) {
      display: flex;
      background-size: 75%;
    }
    &:before{
      content:"";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 50%;
      width: 100%;
      background-image: linear-gradient(180deg, rgba(0, 8, 16, 0) 0, rgb(0,8,16) 100%);
    }
  }
  &:before {
    content: '';
    background-image: linear-gradient(180deg, #000a15 0%, rgba(25, 25, 25, 0) 100%);
    position: absolute;
    width: 96%;
    height: 50px;
    top: 10px;
    left: 2%;
    z-index: 1;
    @include media-breakpoint-up(lg) {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
  }

  // mobile big widget
  &.active {
    padding: 20px 15px;
    position: fixed;
    height: calc(100% - 55px);
    width: 100%;
    left: 0;
    top: 55px;
    .all-podcast{
      display: flex;
      padding-top: 38px;
    }
    .radioBg {
      display: block;
      background-size: 300px;
    }
    .broadcast-info {
      padding-left: 100px;
      margin-top: 100px;
      margin-bottom: 80px;
    }
    #radyodPlayer {
      .video-js {
        height: 63px;
      }
    }
    .drop-radio {
      transform: rotate(90deg);
      z-index: 1;
    }

    .broadcast-player {
      position: relative;
      .player-artist-img {
        display: block;
      }
      .player-artist-detail {
        float: left;
        .subtext {
          float: right;
          .song-name {
            font-size: 18px;
            font-weight: 700;
            height: auto;
            text-overflow: ellipsis;
            max-width: 70%;
            em {
              font-size: 14px;
              font-weight: normal;
            }
          }
          .name {
            display: none;
          }
        }
        .player-content {
          position: relative;
          float: left;
        }
      }
      .songs-list {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
      }

      .song-info {
        width: 50%;
        display: grid;
      }

      .song-info > span {
        color: rgba(255, 255, 255, 0.6);
        font-size: 12px;
        height: 50px;
      }

      .song {
        display: inline-flex;
        align-items: center;
      }

      .song-text {
        font-size: 13px;
        padding: 0 4px;
      }
    }
    .join-instagram {
      display: flex;
    }
  }

  //mobile small widget
  &.small {
    background-image: linear-gradient(to top, #002038 0%, rgba(26, 50, 70, 0.65) 100%) !important;
    border-radius: 7px;
    background-size: 100%;
    @include media-breakpoint-down(lg){
      backdrop-filter: blur(10px);
      background: transparent;
    }
    &:before {
      display: none;
    }
    .broadcast-info {
      display: none;
    }
    @include media-breakpoint-up(lg) {
      background-image: none !important;
    }
    .player-content {
      position: relative;
      #radyodPlayer {
        position: absolute;
        right: 75px;
        top: -6px;
        .vjs-control-bar {
          top: -40px;
          .vjs-play-control {
            width: 35px;
            height: 35px;
            &:before {
              padding: 3px 0;
              font-size: 25px;
            }
          }
          .vjs-volume-menu-button {
            display: none !important;
          }
        }
      }
    }
  }

  //button
  .drop-radio {
    padding: 8px;
    float: right;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: auto;
    transform: rotate(-90deg);
    z-index: 1;
    @include media-breakpoint-up(lg) {
      display: none;
    }
    i {
      font-size: 22px;
    }
  }
  .social-media-dividing-canopy {
    height: 50px;
    position: relative;
    margin-left: -50px;
    margin-right: -50px;
  }
  .social-media-context-title {
    text-align: start;
    font-size: 2.5em;
    text-transform: capitalize;
    position: relative;
    top: -65px;
    z-index: 6;
    left: 6px;
  }
  
  .social-media-interactive-portfolio {
    position: relative;
    top: -70px;
    padding: 10px 0;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      gap: 15px;
    }

    img {
      width: 36px;
      height: 36px;
    }
  }
}

.broadcast-info {
  position: relative;
  margin: 70px 0 30px 20px;
  float: right;
  @include media-breakpoint-up(lg) {
    margin: 100px 0 80px 0;
    width: 70%;
  }
  @include media-breakpoint-up(xl) {
    width: 50%;
    margin-top: 160px;
  }
  .title {
    font-size: 12px;
    color: rgba($color-white, 0.6);
    cursor: pointer;
    i {
      padding: 0 5px 0 10px;
      font-size: 16px;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        width: 10px;
        height: 18px;

        margin-left: -27px;
      }
    }
    .radio-share-icon {
      border-left: 2px solid;
      margin-left: 10px;
    }
  }
  .desc {
    font-size: 24px;
    font-weight: 900;
    margin: 5px 0;
    @include media-breakpoint-up(lg) {
      line-height: 31px;
    }
  }
  .link {
    font-size: 14px;
    position: relative;
    padding: 0 25px;
    display: none;
    &:before {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      left: 3px;
      bottom: 3px;
      background: $color-red;
    }
    &:after {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      left: 0;
      bottom: 0;
      border: 1px solid $color-red;
    }
    i {
      padding: 5px;
    }
  }
}

.broadcast-player {
  width: 100%;
  overflow: hidden;
  display: block;
  margin-bottom: 70px;
  position: relative;
  &.type-single {
    overflow: initial;
    float: left;
    .player {
      overflow: inherit;
    }
    .player-element-wrapper {
      justify-content: flex-start;
    }
    .progress-bar-wrapper {
      display: none !important;
    }
  }
  .player {
    display: flex;
    overflow: hidden;
    margin-bottom: 10px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 12px;
    }
    &-artist-img {
      display: none;
      padding-right: 13px;
      @include media-breakpoint-up(lg) {
        display: block;
        padding-right: 18px;
      }
      img {
        border-radius: 7px;
        width: 92px;
      }
    }
    &-artist-detail {
      display: flex;
      flex-direction: column;
      position: relative;
      flex: 1;
      width: calc(100% - 110px);
      .subtext {
        padding-right: 10px;
        margin-bottom: 10px;
        @include media-breakpoint-up(lg){
          padding-right: 0;
        }
        .song-name {
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
          height: 20px;
          overflow: hidden;
          @include line-clamp(1);
        }
        .name {
          font-size: 14px;
          font-weight: 700;
          display: block;
          height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 70%;
          @include line-clamp(1);
          @include media-breakpoint-up(lg) {
            display: none;
          }
        }
      }
      .player-content {
        display: block;
        width: 100%;
      }
    }
  }
}

.all-podcast {
  overflow: hidden;
  position: absolute;
  bottom: 40px;
  width: 100%;
  left: 0;
  display: none;

  @include media-breakpoint-up(lg) {
    display: flex;
    padding: 38px 0 0 0;
    bottom: 0;
    margin-top: 20px;
  }
  > span{
    width: 80px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-size: 70px;
    background-position: 5px;
    background-repeat: no-repeat;
  }
  &-wrap {
    background-color: $color-black;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width:100%;
    padding:8px 8px 8px 90px;
    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }
    .figure {
      display: flex;
      justify-content: center;
      flex-direction: column;
      em {
        color: rgba($color-white, 0.6);
        font-size: 12px;
      }
      h3 {
        font-size: 14px;
      }
    }
    .outline {
      font-size: 12px;
      display: none;
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        height: 40px;
        padding: 0 15px;
        align-items: center;
        width: fit-content;
      }
    }
  }
}

.songs-list {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.song-info {
  width: 50%;
  display: grid;
}

.song-info > span {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  height: 50px;
}

.song {
  display: inline-flex;
  align-items: center;
}

.song-text {
  font-size: 13px;
  padding: 0 4px;
}

.join-instagram {
  border-radius: 7px;
  background-image: linear-gradient(to right, #feda75 0%, #fa7e1e 5%, #d62976 18%, #962fbf 26%, #694bcd 35%, #00ceff 100%);
  font-size: 14px;
  display: none;
  padding: 0 8px;
  height: 36px;
  position: relative;
  justify-content: space-between;
  align-items: center;
  @include media-breakpoint-up(md) {
    display: flex;
  }
  i {
    font-size: 26px;
  }
  .text {
    padding-left: 5px;
    flex: 1;
  }
  .radyod {
    font-weight: 700;
  }
}

.duration-status {
  background-image: linear-gradient(to top, #000a15 0%, #000810 31%, rgba(25, 25, 25, 0) 100%);
  height: 556px;
  position: absolute;
  width: 100%;
  text-align: center;
  display: none;
  overflow: hidden;
  z-index: 999;
  left: 0;
  top: 10px;
  padding-top: 225px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    background:#112435;
    height:100%;
    text-align: left;
    align-items: flex-start;
    flex-direction: row;
    padding:0 10px;
  }

  &-text {
    font-size: 14px;
    font-weight: 900;
    margin-bottom: 20px;
    @include media-breakpoint-down(sm) {
      margin-bottom:0;
      height:42px;
      overflow: hidden;
      font-weight: 700;
    }
    span {
      display: block;
      font-weight: normal;
      @include media-breakpoint-down(sm) {
        font-size:12px;
      }
    }
  }
  button {
    width: 134px;
    height: 40px;
    display: flex;
    padding: 0 !important;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 30px rgba(13, 119, 209, 0.4);
    @include media-breakpoint-down(sm) {
      width:119px;
      margin-left:8px;
    }
    i {
      font-size: 10px;
      padding-right: 9px;
    }
  }
  &.show {
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }
}
