.broadcast-container {
  position: relative;
  padding: 24px 24px 0;
  @include media-breakpoint-up(lg) {
    padding: 0;
    margin-top: 18px;
  }
  .broadcast-day {
    overflow: hidden;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cat-title {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 24px;
      }
    }
    .broadcast-dropdown {
      .select-day {
        border-radius: 6px;
        padding: 6px 10px 6px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        background-image: linear-gradient(180deg, $color-cerulean2 0%, $color-denim 100%);

        @include media-breakpoint-up(sm) {
          &:hover {
            background: #0591e2;
          }
        }
        &.active {
          background: #fff;
          color: $color-cerulean;
          border-radius: 6px 6px 0 0;
          position: relative;
          z-index: 3;
          i {
            transform: rotate(180deg);
          }
        }
        i {
          margin-left: 25px;
          font-size: 18px;
          transition: color 0.2s ease-out;
        }
      }
      .breadcrumb-sub-nav {
        z-index: 2;
        background: $color-white;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.36);
        border-radius: 6px 0 6px 6px;
        width: 180px;
        position: absolute;
        top: 30px;
        right: 0;
        .link {
          font-size: 14px;
          font-weight: 700;
          color: $color-black-pearl2;
          display: block;
          text-align: center;
          width: 100%;
          padding: 10px 0;
          @include media-breakpoint-up(sm) {
            &:hover,
            &.active {
              background: $color-cerulean;
              color: $color-white;
              &:first-child {
                border-radius: 6px 0 0 0;
              }
              &:last-child {
                border-radius: 0 0 6px 6px;
              }
            }
          }
        }
      }
    }
  }

  .broadcast-list {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      border-left: 1px dashed rgba($color-white, 0.6);
      top: 50px;
      height: calc(100% - 100px);
      z-index: -1;
      margin-left: 30px;
      @include media-breakpoint-up(lg) {
        margin-left: 40px;
      }
    }
    &-item {
      overflow: hidden;
      margin-bottom: 40px;
      display: flex;
      position: relative;
      @include media-breakpoint-up(lg) {
        margin-bottom: 30px;
      }
      &.active {
        .hour,
        .img-wrp h2 {
          color: rgba($color-white, 1);
        }
      }
      .hour {
        width: 60px;
        text-align: center;
        color: rgba($color-white, 0.6);
        font-size: 14px;
        background: $color-black-pearl2;
        font-weight: 700;
        position: absolute;
        top: 50%;
        margin-top: -15px;
        padding: 5px 0;
        @include media-breakpoint-up(lg) {
          font-size: 18px;
          width: 80px;
        }
      }
      .img-wrp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        padding-left: 70px;
        @include media-breakpoint-up(lg) {
          padding-left: 100px;
        }
        img {
          border-radius: 7px;
          width: 113px;
          @include media-breakpoint-up(lg) {
            width: 177px;
          }
        }
        h2 {
          font-size: 14px;
          padding: 0 3px 0 5px;
          color: rgba($color-white, 0.6);
          display: flex;
          flex: 1;
          padding-left: 10px;
          flex-direction: column;
          @include media-breakpoint-up(lg) {
            font-size: 18px;
            padding-left: 12px;
            flex-direction: row;
            align-items: center;
          }
          .live {
            background: $color-white;
            border-radius: 4px;
            padding: 3px 6px;
            color: $color-cerulean;
            display: block;
            font-size: 11px;
            width: 65px;
            height: 21px;
            text-align: center;
            @include media-breakpoint-up(lg) {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .next-day-button {
    background: $color-blue-zodiac;
    padding: 10px;
    width: 100%;
    border-radius: 7px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 700;
    color: rgba($color-white, 0.6);
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    @include media-breakpoint-up(sm) {
      &:hover {
        background: $color-cerulean;
      }
    }
  }
}
