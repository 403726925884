.swiper-manset-v1 {
  width: 100%;
  position: relative;
  border-radius: 7px;
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #000;
    padding-bottom:40%;
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-size: cover;
      z-index: 1;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        max-height: 140px;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        z-index: 2;
      }
    }
    &[data-shadow="none"]{
      .swiper-slide-bg:after{
        display: none;
      }
    }
    &-active {
      .captions-container,
      .swiper-button {
        opacity: 1;
      }
    }
  }
  .captions-title {
    transition: opacity 0.2s linear;
    color: #fff;
    text-align: left;
    position: absolute;
    left: 15px;
    bottom: 10px;
    z-index: 3;
    max-width: calc(100% - 60px);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
    @include media-breakpoint-up(lg) {
      max-width: calc(100% - 250px);
    }
  }
  .pagination {
    &-container {
      position: absolute;
      left: 15px;
      bottom: 10px;
      z-index: 3;
      display: none;
      @include media-breakpoint-up(lg) {
        left: auto;
        right: 15px;
        bottom: 11px;
        display: block;
        padding:0;
      }
    }
    &-thumbnails {
      cursor: pointer;
      position: relative;
      display: none;
      border:1px solid #fff;
      border-bottom: none;
      border-radius: 3px;
      overflow: hidden;
      width:180px;
      @include media-breakpoint-up(lg) {
        display: block;
      }
      .bg {
        display: none;
        padding-bottom:56.25%;
        background-size:cover;
        &.show {
          display: flex;
        }
      }
      &:after {
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      }
    }
    &-next-container {
      position: absolute;
      bottom: 7px;
      left: 7px;
      z-index: 2;
      color: #fff;
    }
    &-next-label {
      font-size: 11px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 7px;
    }
    &-next-title {
      font-size: 15px;
      font-weight: 700;
    }
    &-progress-bar {
      height: 5px;
      width: 100%;
      background: #fff;
      position: relative;
      display: none;
      z-index: 1;
      @include media-breakpoint-up(lg) {
        display: block;
      }
      span {
        background-image: linear-gradient(92deg, #00a5ef 0%, #0d77d1 100%);
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 0;
        border-radius: 0 0 0 3px;
        animation: progressbar-fill 10s linear forwards;
      }
    }
  }
  .swiper-pagination {
    position: relative;
    margin-top: 7px;
    width:100%;
    display: flex;
    justify-content: space-between;
    .swiper-pagination-bullet {
      height: 5px;
      border-radius: 3px;
      margin: 0 3px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
      background-color: #fff;
      flex:1;
      &:first-child{
        margin-left:0;
      }
      &:last-child{
        margin-right:0;
      }
    }
  }

  .swiper-button {
    opacity: 0;
    transition: opacity 0.2s linear;
    position: absolute;
    bottom: 10px;
    right: 10px;
    margin-left: 0;
    z-index: 3;
    @include media-breakpoint-up(md) {
      bottom: 20px;
      right: 20px;
    }
    @include media-breakpoint-up(lg) {
      margin-left: -85px;
      bottom: -15px;
      left: 50%;
      right: auto;
    }
    &.button-fill {
      display: none;
      @include media-breakpoint-up(md) {
        display: flex;
      }
    }
    &.button-play {
      min-width: initial;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}

@keyframes progressbar-fill {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.mobile-manset-pagination {
  margin-top: 7px;
  display: flex;
  justify-content: center;

  .swiper-pagination .swiper-pagination-bullet {
    height: 5px;
    border-radius: 3px;
    margin: 0 3px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    flex: 1 1;
    min-width: 15px;
    &.active {
      opacity: 1;
    }
}
} 