.new-card {
  display: flex;
  padding: 0;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
  cursor: pointer;

  figure {
    position: relative;
    height: 155px;
    width: 150px;
    flex-shrink: 0;
    background: #000810;
    border-radius: 10px;
    padding: 1rem;

    @include media-breakpoint-only(lg) {
      height: 135px;
      width: 120px;
    }

    @include media-breakpoint-down(md) {
      height: 170px;
      width: 155px;
    }

    @include media-breakpoint-down(sm) {
      height: 160px;
      width: 145px;
    }

    .img-wrap {
      position: absolute;
      top: -15%;
      left: 0;
      transition: filter .5s ease-in-out;
      height: calc(100% + 15%);

      img {
        height: 100%;
        width: auto;
        max-width: 150%;
      }
    }

    figcaption {
      width: 56px;
      height: 18px;
      background: #FF2929;
      border: 0.5px solid rgba(255, 255, 255, 0.2);
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 10px;
      bottom: 10px;

      img {
        margin: 0 .5rem;
      }

      p.live {
        text-transform: uppercase;
        color: $color-white;
        font-weight: 700;
        font-size: 9px;
        line-height: 18px;
        width: 56px;
        height: 18px;
      }
    }
  }

  .content {
    background: rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    height: 125px;
    width: calc(100% - 140px + 40px);
    left: -40px;
    flex-shrink: 0;
    padding: 16px;

    @include media-breakpoint-only(lg) {
      width: calc(100% - 120px + 30px);
      left: -30px;
    }

    @include media-breakpoint-down(md) {
      width: calc(100% - 155px + 40px);
      left: -40px;
    }

    @include media-breakpoint-down(sm) {
      width: calc(100% - 145px + 40px);
      left: -40px;
    }

    h3 {
      font-weight: 800;
      font-size: 14px;
      line-height: 22px;
      color: rgba(130, 208, 252, 1);
      text-transform: uppercase;
      margin: 0;
      padding: 0;
      @include line-clamp(2);

      @include media-breakpoint-only(md) {
        font-size: 12px;
        line-height: 19px;
      }
    }

    .time {
      padding: 7px 8px;
      margin: 0;
      display: flex;
      align-items: center;
      background: #0E2C46;
      border: 0.5px solid rgba(255, 255, 255, 0.2);
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
      border-radius: 3px;

      p {
        color: $color-white;
        font-weight: 700;
        font-size: 10px;
        line-height: 17px;
        text-transform: uppercase;
        @include line-clamp(2);
      }
    }
  }

  &.default {
    transition: filter .5s ease-in-out;

    figure {
      height: 260px;
      width: 230px;

      @include media-breakpoint-only(lg) {
        height: 190px;
        width: 170px;
      }

      @include media-breakpoint-down(md) {
        height: 185px;
        width: 163px;
      }

      @include media-breakpoint-down(sm) {
        height: 190px;
        width: 165px;
      }

      figcaption {
        position: absolute;
        bottom: 15px;
        height: 28px;
        width: 66px;
        left: 15px;

        p.live {
          font-weight: 800;
          font-size: 11px;
          line-height: 18px;
        }
      }
    }

    .content {
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      height: 70%;
      width: calc(100% - 230px + 55px);
      left: -55px;
      flex-shrink: 0;

      @include media-breakpoint-only(lg) {
        width: calc(100% - 170px + 42px);
        left: -42px;
        padding: 12px;
      }

      @include media-breakpoint-down(md) {
        width: calc(100% - 163px + 37px);
        left: -37px;
        padding: 12px;
      }

      @include media-breakpoint-down(sm) {
        width: calc(100% - 165px + 37px);
        left: -35px;
      }

      h3 {
        font-size: 18px;
        line-height: 29px;

        @include media-breakpoint-down(lg) {
          font-size: 14px;
          line-height: 22px;
        }
      }

      .time {
        p {
          font-size: 11px;
          line-height: 17px;

          @include media-breakpoint-down(lg) {
            font-size: 10px;
          }
        }
      }
    }

    &.live {
      will-change: filter;
      filter: drop-shadow(0px 6px 25px rgba(13, 119, 209, 0.58));

      figure {
        .img-wrap {
          will-change: filter;
          filter: drop-shadow(0px 3px 30px rgba(13, 119, 209, 0.58));
        }
      }
    }
  }
}
