.events-container {
  position: relative;
  @include media-breakpoint-up(lg) {
    margin-top: 18px;
  }

  .events-header {
    overflow: hidden;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cat-title {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .events-dropdown {
      .select-month {
        border-radius: 6px;
        padding: 8px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        background-image: linear-gradient(180deg, $color-cerulean2 0%, $color-denim 100%);

        @include media-breakpoint-up(sm) {
          &:hover {
            background: #0591e2;
          }
        }

        &.active {
          background: #fff;
          color: $color-cerulean;
          border-radius: 6px 6px 0 0;
          position: relative;
          z-index: 3;

          i {
            transform: rotate(180deg);
          }
        }

        i {
          margin-left: 10px;
          font-size: 18px;
          transition: color 0.2s ease-out;
        }
      }

      .breadcrumb-sub-nav {
        z-index: 2;
        background: $color-white;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.36);
        border-radius: 6px 0 6px 6px;
        width: 180px;
        position: absolute;
        top: 34px;
        right: 0;
        max-height: 260px;
        overflow-y: auto;

        @include customScroll(6px, 0, $color-cerulean, $color-cerulean, $color-white, 0, 0);

        .link {
          font-size: 14px;
          font-weight: 700;
          color: $color-black-pearl2;
          display: block;
          text-align: center;
          width: 100%;
          padding: 10px 0;
          @include media-breakpoint-up(sm) {
            &:hover,
            &.active {
              background: $color-cerulean;
              color: $color-white;

              &:first-child {
                border-radius: 6px 0 0 0;
              }

              &:last-child {
                border-radius: 0 0 0 6px;
              }
            }
          }
        }
      }
    }
  }

  .events-card {
    $self: &;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
    background: #000810;
    border-radius: 10px;

    .img-wrap {
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      padding-bottom: 100%;
      backface-visibility: hidden;
      transform: translate3d(0, 0, 0);
      border: 0.5px solid rgba(255, 255, 255, 0.2);

      .bg-image {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-size: cover;
        transition: transform 0.2s ease-out;
        width: 100%;
        will-change: transform;
      }
    }

    .content-wrap {
      .meta {
        font-size: 12px;
        color: #0591E2;
        font-weight: 700;
        border-bottom: 1.6px solid #031324;;

        > span {
          position: relative;
          padding-left: 20px;
          display: flex;
          align-items: center;

          > span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            max-height: 100%;
          }

          &.date {
            height: 20px;

            > span {
              @include line-clamp(1);
            }
          }

          &.location {
            height: 45px;

            > span {
              @include line-clamp(2);
            }
          }
        }

        i {
          font-size: 16px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .desc {
        font-size: 14px;
        font-weight: 700;
        color: #bebebe;
        height: 40px;
        margin-top: 15px;
        text-overflow: ellipsis;
        @include line-clamp(2);

        .spot {
          display: inline;
          color: #ffffff;
        }

        .detail {
          p {
            display: inline;
          }
        }
      }
    }
  }

  .events-list {
    row-gap: 20px;
  }

  .no-events {
    text-align: center;
    padding: 40px 0;
    color: rgba(255, 255, 255, 0.3);

    .bg-noevent {
      background-image: url(../img/icon-noevent.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      width: 80px;
      height: 80px;
      display: inline-block;
      margin-bottom: 30px;
    }

    p {
      font-size: 18px;
    }
  }

  @include media-breakpoint-down(md) {
    padding-right: 42px;
    padding-left: 42px;
    .events-header {
      margin: 24px 0 32px;

      .events-dropdown {
        .breadcrumb-sub-nav {
          top: 34px;
          right: 20px;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding-right: 30px;
    padding-left: 30px;
    .events-header {
      margin: 24px 0 32px;
    }
    .events-card {
      .img-wrap {
        width: 100% !important;
        padding-bottom: 100% !important;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    padding-right: 0;
    padding-left: 0;

    .events-list {
      row-gap: 8px;
      margin-right: 0;
      margin-left: 0;

      .item {
        padding-right: 0;
        padding-left: 0;
      }
    }

    .events-header {
      margin-right: 30px;
      margin-left: 30px;
    }

    .events-card {
      flex-direction: row;
      padding: 16px 30px;

      .img-wrap {
        width: 141px !important;
        padding-bottom: 25% !important;
        flex-shrink: 0;
      }
    }
  }
}
