﻿.responsive-banner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;

    & > .item {
        padding: 3px;

        & > a {
            display: inline-block;
        }
    }
}
