.popup-search{
	$self:&;
	bottom: 0;
	left: 0;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: 0;
	top: 0;
	transition: opacity .3s ease-out;
	z-index: 99999;

	*{
		box-sizing: border-box;
	}

	&-overlay{
		background-color: rgba($color-black-pearl2, .7);
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		backdrop-filter: blur(5px) contrast(0.8) brightness(1.3);
	}
	// .popup-search-close
	&-close {
		background: $color-white;
		border-radius: 50%;
		cursor: pointer;
		height: 23px;
		position: absolute;
		right: 15px;
		top: 19px;
		transition: opacity 0.3s linear;
		width: 23px;
		z-index: 11;
		@include media-breakpoint-up(xl){
			right: 19px;
		}
		&:after,
		&:before {
			background-color: $color-black;
			content: ' ';
			height: 15px;
			top: 4px;
			left: 11px;
			position: absolute;
			transform: rotate(45deg);
			width: 2px;
		}
		&:after {
			transform: rotate(-45deg);
		}
	}

	// .popup-search-container
	&-container{
		background-color: $color-blue-charcoal;
		display: flex;
		flex-direction: column;
		margin-left: auto;
		margin-right: auto;
		min-height: 189px;
		position: relative;
		width: 100%;
		@include media-breakpoint-up(lg){
			border-radius: 7px;
			margin-top: 86px;
			min-height: 146px;
			width: 719px;
		}
		@include media-breakpoint-up(xl){
			margin-top: 32px;
			min-height: 157px;
			width: 896px;
		}
	}

	// .popup-search-input-row
	&-input-row{
		margin-top: 72px;
		margin-bottom: 20px;
		@include media-breakpoint-up(lg){
			margin-top: 29px;
		}
		@include media-breakpoint-up(xl){
			margin-top: 40px;
		}
		#{$self}-form{
			margin: 0 10px;
			position: relative;
			@include media-breakpoint-up(lg){
				margin-left: 28px;
				margin-right: 28px;
			}
			@include media-breakpoint-up(xl){
				margin-left: 40px;
				margin-right: 40px;
			}
		}

		#{$self}-input{
			background: transparent;
			border: 1px solid $color-white;
			border-radius:7px;
			box-sizing: border-box;
			color:$color-white;
			font-size: 14px;
			font-weight: 700;
			height: 50px;
			line-height: 18px;
			padding: 15px 45px 15px 16px;
			width: 100%;

			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				opacity: .6;
			}
			&::-moz-placeholder { /* Firefox 19+ */
				opacity: .6;
			}
			&:-ms-input-placeholder { /* IE 10+ */
				opacity: .6;
			}
			&:-moz-placeholder { /* Firefox 18- */
				opacity: .6;
			}
		}
	}

	// .popup-search-btn
	&-btn{
		@extend .fa;
		@extend .fa-search;
		border-bottom-right-radius: 8px;
		border-top-right-radius: 8px;
		font-size: 24px;
		height: 100%;
		opacity: .6;
		position: absolute;
		right: 0;
		top: 0;
		transition: opacity .2s ease-out;
		width: 45px;
		will-change: opacity;
		padding-right: 15px;
		&:before{
			//transform: translate(-50%, -50%);
		}
		.no-touch &:hover, .touch &:active{
			opacity: 1;
		}
	}

	// .popup-search-tag-list
	&-tag-list{
		display: flex;
		margin-top: 20px;
		-webkit-overflow-scrolling: touch;
		overflow-y: auto;
		@include media-breakpoint-up(xl){
			overflow-y: hidden;
		}
		&:before{
			@include media-breakpoint-up(lg){
				content:"";
				margin-left: 18px;
			}
			@include media-breakpoint-up(xl){
				margin-left: 30px;
			}
		}
		&:after{
			@include media-breakpoint-up(lg){
				content:"";
				margin-right: 18px;
			}
			@include media-breakpoint-up(xl){
				margin-right: 30px;
			}
		}
		&::-webkit-scrollbar {
			display: none;
		}
		.item{
			flex-shrink: 0;
			padding-left: 10px;
			padding-right: 10px;
			opacity: .3;
			transition: opacity .3s ease-out;
			&.history .tag:before{
				@extend .far;
				@extend .fa-clock;
			}
			&.trend .tag:before{
				@extend .fa;
				@extend .fa-chart-line;
			}
			.no-touch &:hover, .touch &:active {
				opacity: 1;
			}
		}
		.tag{
			border: solid 1px $color-white;
			border-radius:21px;
			color:$color-white;
			display: block;
			font-size: 11px;
			font-weight: 400;
			line-height: 25px;
			padding: 0 30px 0 10px;
			position: relative;
			min-width: 80px;
			cursor: pointer;
			&:before {
				position: absolute;
				right: 11px;
				font-size: 14px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	// .popup-search-result-row
	&-result-row{
		display: flex;
		flex-direction: column;
		height: 0;
		opacity:0;
		pointer-events: none;
		transition: height 0.7s cubic-bezier(0, 1, 0.5, 1) 0.3s, opacity .3s ease-out;
		@include media-breakpoint-up(lg){
			padding-left: 18px;
			padding-right: 18px;
		}
		@include media-breakpoint-up(xl){
			padding-left: 30px;
			padding-right: 30px;
		}
		.loading-container {
			margin: 0;
			height: 40px;
			left: 50%;
			opacity: 0;
			pointer-events: none;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			transition: opacity .2s ease-out;
			will-change: opacity;
			z-index: 2;
		}
		.section-title{
			margin-bottom: 15px;
			font-size: 14px;
			font-weight: 700;
			line-height: 19px;
			@include media-breakpoint-up(lg){
				font-size: 18px;
				line-height: 23px;
				margin-bottom: 17px;
			}
			@include media-breakpoint-up(xl){
				margin-bottom: 12px;
			}
		}
		.section-swiper {
			pointer-events: none;
			opacity: 0;
			transition: opacity .3s ease-out;
		}
		.section-swiper .swiper-navigation-prev, .section-swiper .swiper-navigation-next{
			left: -28px;
			padding: 7px;
		}
		.section-swiper .swiper-navigation-next{
			left: auto;
			right: -28px;
		}
		.ps-result-inner {
			position: relative;
		}
		.ps-no-result {
			display: flex;
			height: 100%;
			left: 0;
			opacity: 0;
			pointer-events: none;
			position: absolute;
			text-align: center;
			top: 0;
			transition: opacity .3s ease-out;
			width: 100%;
			p {
				font-size: 12px;
				font-weight: 400;
				margin: auto;
				@include media-breakpoint-up(lg){
					font-size: 16px;
				}
			}
		}
		.section{
			margin-bottom: 0;
			.ps-result-inner {
				min-height: 188px;
				@include media-breakpoint-up(lg){
					min-height: 164px;
				}
				@include media-breakpoint-up(xl){
					min-height: 193px;
				}
			}
			&.main-results {
			}
			&.other-results {
				padding-top: 35px;
				@include media-breakpoint-up(lg){
					padding-top: 49px;
				}
				@include media-breakpoint-up(xl){
					padding-top: 53px;
				}
			}
		}
	}

	&.visible{
		opacity: 1;
		pointer-events: auto;
	}
	&.typing{
		overflow-y: auto;
		#{$self}-result-row{
			transition: height 0.7s cubic-bezier(0, 1, 0.5, 1), opacity .3s ease-out .7s;
			opacity: 1;
			height: 250px;
			pointer-events: auto;
			@include media-breakpoint-up(lg){
				height: 400px;
			}
			@include media-breakpoint-up(xl){
				height: 450px;
			}
		}
		.ps-result-inner {
			.loading-container {
				opacity: 1;
			}
			&.loaded{
				.loading-container {
					opacity: 0;
				}
				.section-swiper {
					opacity: 1;
					pointer-events: auto;
				}
				.ps-no-result {
					opacity: 1;
					pointer-events: auto;
				}

				/*&.result{
					.section-swiper {
						opacity: 1;
						pointer-events: auto;
					}
				}
				&.no-result {
					.ps-no-result {
						opacity: 1;
						pointer-events: auto;
					}
				}*/
			}
		}
	}

	.search-result {
		display: flex;
	}
	.ui-button {
		width: fit-content;
		margin: 20px auto;
	}
}

html.search-active{

	overflow: hidden;
	body{
		overflow: visible;
		height: 100%;
	}
}

.search-page {
	display: flex;
	flex-direction: column;
	justify-content: center;

	.message {
		font-size: 14px;
		text-align: center;
	}

	h2 {
		margin: 20px 0;
	}

	.search-content {
		margin-bottom: 20px;
	}

	.pagination-container {
		margin-top: 30px;
	}
}