// Main Colors (theme)
$color-azure-radiance:	#00A5FE;
$color-black-pearl2:    #031324;
$color-black-pearl:     #06111c;
$color-black:           #000;
$color-blue-charcoal2:  #000a15;
$color-blue-charcoal3:  #010A14;
$color-blue-charcoal:   #010f1d;
$color-blue-zodiac:     #0E2C46;
$color-caribbean-green: #0cd09b;
$color-cerulean2:       #00A5EF;
$color-cerulean:        #0591e2;
$color-denim:           #0d77d1;
$color-dusty-gray:      #9a9a9a;
$color-elephant:        #0C2136;
$color-midnight-blue:   #01325d;
$color-midnight:        #002038;
$color-pale-sky:        #6e757b;
$color-rock-blue:       #a2c2d0;
$color-silver:          #bebebe;
$color-slate-gray:      #748798;
$color-tory-blue:       #0e6ba7;
$color-tundora:         #414141;
$color-white:           #fff;
$color-dove-gray:		#707070;
$color-ship-cove:		#6b94b7;
$color-papa-green:		#213341;
$color-cinnabar:		#e94340;
$color-caribbean-green: #0cd09b;
$color-fb:				#3c5899;
$color-red:				#ff0000;
$color-black-caparol:   #000810;
$color-black-transparent: #031324a6;

$gradient-blue:         linear-gradient(112deg, $color-cerulean 0%,$color-denim 100%);
$gradient-blue2:        linear-gradient(-67deg, $color-cerulean 0%,$color-denim 100%);

// Fonts
$font-family-base: Arial, "Noto Sans", sans-serif;
$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts";

// Image hover scale size
$img-scale-size: 1.1;

// Grid gutter
$grid-gutter-width: 20px;
