#radyodPlayer {
  .video-js {
    width: 100%;
    height: 60px;
    background: transparent;
    @extend .fab;
    > *,
    .vjs-big-play-button {
      display: none !important;
    }

    .vjs-play-control {
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      width: 53px;
      height: 53px;
      color: #fff;
      background: transparent;
      border: 2px solid white;
      font-size: 28px;
      border-radius: 50px;

      &::before {
        font-size: inherit;
        line-height: inherit;
        padding: 11px 0;
        @extend .fa-play;
        
      }
      &.vjs-playing {
        &::before {
          @extend .fa-stop;
          padding: 11px 0;
        }
      }
    }

    .vjs-control {
      text-shadow: 0 0 1em #fff;
      &-bar {
        display: flex !important;
        align-items: center;
        visibility: visible !important;
        opacity: 1 !important;
        background-color: transparent;
        top: 15px;
        width: 100%;
        @extend .fab;
        > * {
          display: none !important;
        }
        .vjs-play-control {
          display: inline-block !important;
          float: left;
          background: transparent;
          border: 2px solid white;
          color: white;
        }
        .vjs-volume-menu-button {
          display: inline-block !important;
          vertical-align: top;
          width: calc(100% - 65px);
          speak: none;
          font-style: normal;
          font-weight: 400;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin: 0 0 0 10px;
          &::before {
            font-size: 27px;
            line-height: initial;
            text-shadow: none;
            width: 30px;
            height: 26px;
          }

          .vjs-menu {
            left: 34px;
            top: -3px;
            display: block;
            opacity: 1;
            width: calc(100% - 35px);
            position: relative;
            &-content {
              width: 100%;
              padding:0 10px;
              box-sizing: border-box;
              .vjs-volume-bar {
                height: 5px;
                width: 100%;
                border: none;
                border-radius: 3px;
                background-color: rgba(255,255,255,.4);
                margin:15px 0;
                .vjs-volume-level {
                  height: 5px;
                  border-radius: 3px;
                  &::before {
                    font-size: 15px;
                    text-shadow: none;
                    right: -10px;
                    top: -5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.podcast-radio-widget {
  padding: 25px;
  position: absolute;
  bottom: 0;
  width: 100%;
  .video-js {
    width: 100%;
    height: 50px;
    background: transparent;
    @extend .fab;
    .vjs-loading-spinner{
      top:-120px;
      margin:0 0 0 -25px;

    }
    .vjs-play-control {
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      width: 35px;
      height: 35px;
      color: #fff;
      background: transparent;
      border: 2px solid white;
      font-size: 28px;
      border-radius: 50px;
      margin: -5px 10px 0 0px;
      &::before {
        font-size: inherit;
        line-height: inherit;
        padding: 5px 0;
        font-size: 20px;
        @extend .fa-play;
      }
      &.vjs-playing {
        &::before {
          @extend .fa-stop;
          padding: 5px 0;
          font-size: 20px;
        }
      }
    }

    .vjs-volume-menu-button {
      &::before {
        font-size: 27px;
        line-height: initial;
        text-shadow: none;
        width: 30px;
        height: 26px;
      }

      .vjs-menu {
        &-content {
          background: none;
        }
      }
    }

    .vjs-control {
      &-bar {
        background: none;
      }
    }

    .vjs-settings-button,
    .vjs-fullscreen-control {
      display: none;
    }

    .vjs-menu {
      &-content {
        .vjs-volume-bar {
          .vjs-volume-level {
            height: 6px;
            &::before {
              font-size: 18px;
              text-shadow: none;
              left: -7px;
              top: -6px;
            }
          }
        }
      }
    }

    .vjs-progress-control {
      height: 5px;
      width: 100%;
      border-radius: 5px;
      margin-top: 12px;
      .vjs-progress-holder {
        height: 5px;
        background: rgba(255,255,255,.4);
        margin: 0;
        .vjs-play-progress {
          &:before {
            font-size: 18px;
            margin-top: 0px;
          }
        }
      }
      .vjs-slider-bar{
        background: #fff;
      }
    }

    .vjs-current-time {
        padding: 0 7px 0 0;
    }
    .vjs-current-time-display,
    .vjs-duration-display {
      font-size: 12px;
      font-family: "Muli", sans-serif;
    }

  }
  .vjs-big-play-button {
    display: none !important;
  }
  .vjs-user-inactive,
  .vjs-user-active  {
    .vjs-control-bar {
      opacity: 1 !important;
      display: inline-flex !important;
    }
  }
}
