.validate-input-container {
  margin: 3px 5px 16px 5px;
  input,
  select,
  textarea {
    color: $color-white;
    height: 55px;
    padding: 10px;
    width: 100%;
    font-size: 14px;
    background-color: transparent;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    border: 1px solid rgba($color: $color-white, $alpha: 0.2);
    transition: all linear 0.1s;

    &::placeholder {
      visibility: unset;
      transition-delay: 0.1s;
    }
  }

  input {
    &:focus,
    &:not([value='']) {
      border: 1px solid rgba($color: $color-white, $alpha: 1);
      &::placeholder {
        visibility: hidden;
        transition-delay: 0s;
      }
      & + label {
        visibility: unset;
        top: -36px;
        opacity: 1;
        background-color: $color-black-pearl2;
        margin: 30px 25px;
        padding: 0 5px;
        font-size: 14px;
      }
    }
  }

  select {
    &:focus {
      border: 1px solid rgba($color: $color-white, $alpha: 1);
      &::placeholder {
        visibility: hidden;
        transition-delay: 0s;
      }
      & + label {
        visibility: unset;
        top: -36px;
        opacity: 1;
        background-color: $color-black-pearl2;
        margin: 30px 25px;
        padding: 0 5px;
        font-size: 14px;
      }
    }
    option {
      color: #000;
    }
    option:nth-child(1) {
      color: rgba($color: $color-white, $alpha: 0.2);
    }
  }

  textarea {
    &:focus,
    &:not(:empty) {
      border: 1px solid rgba($color: $color-white, $alpha: 1);
      &::placeholder {
        visibility: hidden;
        transition-delay: 0s;
      }
      & + label {
        visibility: unset;
        top: -36px;
        opacity: 1;
        background-color: $color-black-pearl2;
        margin: 30px 25px;
        padding: 0 5px;
        font-size: 14px;
      }
    }
  }

  textarea {
    height: 120px;
  }

  label {
    visibility: hidden;
    border-radius: 7px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
    margin: 20px 25px;
    font-size: 14px;
    opacity: 0.6;
    transition: all linear 0.1s;
  }

  .input-valid-message {
    margin: 0 5px;
    font-size: 12px;
    color: $color-cinnabar;
  }
}
