.list-detail {
  padding: 24px 30px 0;
  @include media-breakpoint-up(lg) {
    padding: 10px 0;
  }
  &-header {
    margin-bottom: 25px;
    h1 {
      font-size: 18px;
      line-height: 26px;
      @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 35px;
      }
    }
    .date {
      font-size: 12px;
      line-height: 18px;
      color: $color-silver;
      margin-top: 9px;
      display: block;
      opacity: 0.6;
    }
    p {
      font-size: 14px;
      line-height: 25px;
      margin: 10px 0;
    }
    .social-links {
      border-top: 1px solid rgba($color-white, 0.2);
      padding: 15px 0;
      button {
        font-size: 13px;
        font-weight: 700;
        color: rgba($color-white, 0.6);
        margin-right: 23px;
        i {
          font-size: 16px;
        }
      }
    }
  }
  &-container {
    .list-detail-item {
      font-size: 13px;
      margin-bottom: 25px;
      img {
        margin-bottom: 15px;
        border-radius: 4px;
        &.loading {
          min-height: 500px;
        }
      }
      p {
        font-size: 14px;
        line-height: 25px;
        opacity: 0.6;
      }

      &-image {
        margin: 10px auto;
      }
    }
  }

  .photo-gallery-container {
    .photo-gallery-item {
      max-width: 1024px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      img {
        margin: 0 auto;
        width: 100%;
        border-radius: 7px;
        max-width: 700px;
      }
      p {
        color: $color-white;
        opacity: 0.6;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        margin-bottom: 50px;
        margin-top: 20px;
        text-align: center;
      }
    }
  }

  .news-section {
    padding: 0;
  }
}
