footer {
  max-width: 1160px;
  margin: 50px auto 0;
  .share-apps {
    border-top: 1px solid rgba($color-white, 0.2);
    text-align: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 0 0;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      text-align: left;
      align-items: flex-end;
    }
    @include media-breakpoint-up(xl) {
      justify-content: space-between;
    }
    &-items {
      $self: &;
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      @include media-breakpoint-up(lg) {
        margin: 0;
      }

      &.social {
        flex-direction: column;
        .social-title {
          font-size: 14px;
          font-weight: 700;
          color: rgba($color-white, 0.2);
          margin: 0 0 20px;
          width: 100%;
          text-align: center;
          @include media-breakpoint-up(lg) {
            text-align: left;
          }
        }
        .social-list {
          $self: &;
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          @include media-breakpoint-up(lg) {
            width: 100%;
            justify-content: left;
          }
          li {
            padding: 0 14px;
            &:first-child() {
              padding-left: 0;
            }
            &:last-child() {
              padding-right: 0;
            }
          }
          .social-link {
            color: $color-slate-gray;
            transition: color 0.2s ease-out;

            .fa-facebook-square {
              font-size: 30px;
            }
            .fa-twitter {
              font-size: 32px;
            }
            .fa-instagram {
              font-size: 32px;
            }
            .fa-youtube {
              font-size: 34px;
            }

            @include media-breakpoint-up(sm) {
              &:hover {
                color: $color-white;
              }
            }
          }

          .social-tw {
            a {
              display: block;
              color: $color-slate-gray;

              &:hover {
                color: $color-white;
              }
            }
          }
        }
      }
      &.stores {
        $self: &;
        .link {
          margin: 0 10px;
          border-radius: 5px;
          display: flex;

          @include media-breakpoint-up(sm) {
            &:hover {
              background: $color-black;
            }
          }

          img {
            height: 43px;
            @include media-breakpoint-up(lg) {
              height: 39px;
            }
          }
        }
      }
    }

    .partners {
      padding: 30px 0;
      @include media-breakpoint-up(lg) {
        padding: 35px 0 0;
      }
      &-link {
        margin: 0 16px;
        opacity: 0.6;
        img {
          height: 31px;
          @include media-breakpoint-up(lg) {
            height: 38px;
          }
        }
        @include media-breakpoint-up(sm) {
          &:hover {
            opacity: 1;
          }
        }

        @include media-breakpoint-up(lg) {
          &:last-child() {
            margin-right: 0;
          }
          &:first-child() {
            margin-right: 0;
          }
        }
      }
    }
  }

  .footer-nav {
    overflow: hidden;
    text-align: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include media-breakpoint-up(lg) {
      margin: 30px 0 20px;
      order: 4;
    }
    a {
      font-size: 12px;
      opacity: 0.2;
      line-height: 30px;
      padding: 0 15px;
      @include media-breakpoint-up(lg) {
        padding: 0 25px;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .copyright {
    border-top: 1px solid rgba($color-white, 0.2);
    padding: 20px;
    line-height: 18px;
    text-align: center;
    font-size: 12px;
    span {
      opacity: 0.2;
    }
  }
}
