.audience-representative-container {
  padding: 40px 30px;
  @include media-breakpoint-up(md) {
      padding: 24px 42px;
  }
  @include media-breakpoint-up(lg) {
      padding: 0;
  }
  h2 {
    color: $color-white;
    font-size: 18px;
    border-bottom: 1px solid rgba($color: $color-white, $alpha: 0.2);
    padding-bottom: 5px;
    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
  }
  h3 {
    margin: 25px 0 15px 0;
    color: $color-white;
    font-size: 14px;
    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  .contract {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    border: 1px solid rgba($color: $color-white, $alpha: 0.2);
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 400;

    width: 100%;
    height: 200px;
    overflow: auto;
    text-align: justify;
  }

  .approval-container {
    margin: 15px 0;
    font-size: 14px;
    font-weight: 400;
    span {
      color: #ff0000;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .send-button {
    margin: 15px;
    text-align: center;
  }
}
