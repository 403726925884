.section-swiper {
  position: relative;
  .swiper-pagination {
    text-align: right;
    bottom: auto !important;
    opacity: 0;
    position: absolute;
    right: 0;
    top: -30px;
    width: inherit;
    .swiper-pagination-bullet {
      background-color: $color-white;
      border-radius: 4px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
      height: 4px;
      margin-right: 4px;
      width: 33px;
    }
  }
  &[data-pagination='false'] .swiper-pagination {
    display: none;
  }
  .swiper-navigation-prev,
  .swiper-navigation-next {
    cursor: pointer;
    left: -50px;
    margin-top: -33px;
    opacity: 0;
    padding: 20px;
    position: absolute;
    top: 50%;
    transition: opacity 0.2s linear;
    i {
      font-size: 26px;
    }
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  .swiper-navigation-next {
    left: auto;
    right: -50px;
  }
  .swiper-button-disabled {
    opacity: 0;
  }
}
.news-swiper {
  .swiper-container {
    padding-top: 30px;
    margin-top: -30px;
    .swiper-pagination {
      top: 0;
    }

    @include media-breakpoint-down(md) {
      padding-top: 30px;
      .swiper-slide {
        max-width: 45%;
        // padding-left: 10px;
        // padding-right: 10px;
      }
    }

    @include media-breakpoint-down(sm) {
      .swiper-slide {
        max-width: 100%;
      }
    }
  }
}
