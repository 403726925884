.static-page-container {
    padding: 40px 30px;
    @include media-breakpoint-up(md) {
        padding: 24px 42px;
    }
    @include media-breakpoint-up(lg) {
        padding: 5px 0;
    }

    .inline-big-title {
        color: $color-white;
        font-size: 14px;
        font-weight: 700;
        line-height: 23px;
        margin: 10px 0;
        opacity: 1;
        @include media-breakpoint-up(lg) {
            font-size: 18px;
            font-weight: 30px;
            margin: 20px 0;
        }
    }
    h2 {
        color: $color-white;
        font-size: 18px;
        line-height: 24px;
        border-bottom: 1px solid rgba($color: $color-white, $alpha: 0.2);
        padding-bottom: 2rem;
        @include media-breakpoint-up(md) {
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 14px;
        }
    }
    h3 {
        margin-top: 10px;
        padding: 5px 0 15px;
        color: $color-white;
        font-size: 14px;
        line-height: 18px;
        @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 30px;
            padding: 15px 0;
        }
    }
    p {
        opacity: 0.6;
        color: $color-white;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
    }
    .url {
        opacity: 1 !important;
        color: $color-denim;
        b {
            opacity: 0.6;
            color: $color-white;
        }
    }

    .about-container {
        color: $color-white;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        a {
            color: #0591e2;
            font-size: 14px;
            line-height: 25px;
        }
        .container {
            border-bottom: 30px;
            padding: 0;
            .row {
                margin-top: 25px;
                div {
                    opacity: 0.6;
                }
            }
        }
        .address {
            margin: 20px 0;
        }
    }
    .addwebsite-container {
        display: block;
        position: relative;
        .radyod-iframe {
            margin: 20px 0;
            min-height: 100px;
            border-radius: 7px;
            border: 1px solid rgba($color: $color-white, $alpha: 0.2);
            text-align: center;

            .iframe-textbox {
                padding: 10px;
                margin: 15px;
                min-height: 25px;
                border-radius: 7px;
                border: 1px solid $color-white;
                opacity: 0.6;
                color: $color-white;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
            }
            button {
                margin: 15px;
            }
            .iframe-container {
                margin-top: 26px;
                position: relative;
                width: 100%;
                padding-bottom: 56.25%;
                position: relative;
                iframe {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        .copied-toast {
            margin: 15px;
            font-size: 15px;
            padding: 25px;
            border-radius: 7px;
            background: $color-caribbean-green;
        }
        .copied-toast-progress {
        }
        .copied-toast-body {
        }
    }
    .announce-container {
        .container {
            padding: 0 10px;
        }
        .announce-section {
            margin: 15px 0;
        }
        .ads-section {
            font-size: 14px;
            opacity: 0.6;
            a {
                color: #0591e2;
            }
            p {
                display: inline;
            }
        }
    }
    .contact-container {
        .contact-section {
            margin: 15px 0;
            font-size: 14px;
            strong {
                opacity: 0.6;
            }
            p {
                line-height: 20px;
            }
        }
        .google-maps {
            width: 100%;
            left: 0px;
            top: 0px;
            margin: 20px 0;
            padding: 0px;
            position: relative;
            overflow: hidden;
            border-radius: 10px;
            height: 150px;

            @include media-breakpoint-up(md) {
                height: 250px;
            }
        }
    }
    .policy-container,
    .content-container {
        font-family: 'Muli', sans-serif;

        .table-bar {
            ul {
                overflow-x: scroll;

                &.hover {
                    @media screen and (max-width: 1300px) {
                        -webkit-mask-image: linear-gradient(90deg, #fff, #fff, rgba(255, 255, 255, 0));
                    }
                }

                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    display: none;
                }

                li {
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: rgba(255, 255, 255, 0.75);
                    cursor: pointer;
                    display: flex;
                    white-space: pre;
                    align-items: center;
                    border-bottom: 3px solid #031324;

                    &:not(:last-child) {
                        margin-right: 48px;
                    }

                    &.active {
                        border-bottom: 3px solid white;
                        color: white;
                    }
                }
            }
        }

        margin-top: 30px;

        h3 {
            font-size: 18px;
            font-weight: 700;
            font-style: normal;
            text-align: left;
            letter-spacing: normal;
            line-height: normal;
        }

        p {
            color: #9AA1A7;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
        }

        article {
            color: #9AA1A7;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;

            p,
            strong {
                opacity: 1;
                letter-spacing: normal;
                line-height: 25px;
                text-align: left;
                font-style: normal;
                padding-top: 10px;

                a {
                    color: #0591e2;
                    text-decoration: underline;
                }
            }

            strong {
                font-weight: 800;
            }

            ul,
            ul li {
                padding: 5px 0;
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                color: #9AA1A7;

                a {
                    color: white;
                    &:hover {
                        color: #0591e2;
                        opacity: 1;
                    }
                }
            }

            .bg-brown {
                background-color: burlywood;
                border: 4px solid black;
                padding: 10px;
                color: black;

                &.arrow-dsc {
                    list-style-type: disclosure-closed;
                }

                li::marker {
                    color: red;
                }
            }

            .bg-gray {
                color: black;
                background-color: #bebebe;
                padding: 20px;
            }
        }

        .faq {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            margin-top: 20px;

            details {
                &[open] {
                    summary {
                        border-bottom: 0px;
                        color: white;

                        ~ * {
                            transition: all 0.5s ease-in-out;
                        }

                        &::after {
                            transform: rotate(-135deg);
                            transition: all 0.5s ease-in-out;
                            top: 50%;
                            border-color: white;
                        }
                    }
                }

                summary {
                    position: relative;
                    font-weight: 800;
                    font-size: 14px;
                    padding: 24px 48px 24px 24px;
                    cursor: pointer;
                    background: #000810;
                    border-radius: 10px;
                    color: #95989A;

                    &::marker {
                        content: none;
                    }

                    &::after {
                        content: '';
                        border: solid;
                        border-width: 0 3px 3px 0;
                        display: inline-block;
                        padding: 3px;
                        transform: rotate(-45deg);
                        position: absolute;
                        right: 30px;
                        top: 42%;
                        border-color: #bebebe;
                        border-width: 0 2px 2px 0;
                        padding: 4px;
                        transform: rotate(45deg);
                        transition: all 0.5s ease-in-out;
                    }
                }

                p {
                    padding: 17px 0 17px 0;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    line-height: 24px;
                    color: white;
                    font-weight: 400;
                    font-size: 14px;
                    opacity: 1;

                    &::after {
                        content: ' ';
                        position: absolute;
                        bottom: 0;
                        border-bottom: 1px dashed #1c2a39;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        table {
            border-collapse: collapse;
            border-spacing: 0;
            font-size: 12px;
            margin-top: 2rem;

            th,
            td {
                text-align: left;
                padding: 15px 10px;
                vertical-align: top;
                border: 1px solid #fff;
            }

            &.m-5 {
                margin: 1.5rem 0 0 0 !important;
            }

            .bg-black {
                color: white;
                background-color: black;
            }

            .bg-gray {
                color: white;
                background-color: #707070;
            }

            .bg-blue {
                color: white;
                background-color: #0d77d1;
            }

            .bg-light-gray {
                color: #000;
                background-color: #bebebe;
            }
        }
    }
}
