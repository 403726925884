.frequency-container {
  display: block;
  overflow: hidden;
  padding: 24px 30px 0;
  margin-bottom: 30px;
  @include media-breakpoint-up(lg){
    padding:18px 0 0;
  }

  .frequency-card {
    display: flex;
    justify-content: center;
    border-radius: 7px;
    margin-bottom: 18px;
    text-align: center;
    align-items: center;
    position: relative;
    opacity: 1;
    color: $color-white;
    font-weight: 700;
    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 30px;
    }
    &:before {
      content: '';
      background-color: rgba(0, 0, 0, .55);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 7px;
    }
    &.bg-image {
      background-repeat: no-repeat;
      padding-bottom: 50%;
      background-position: center;
      background-size: cover;
    }
    label {
      position: absolute;
      top: 30%;
      font-size: 12px;
    }
    span {
      position: absolute;
      top: 45%;
      font-size: 18px;
    }

    &.large {
      &.bg-image {
        padding-bottom: 36%;
        @include media-breakpoint-up(md) {
          padding-bottom: 60%;
        }
        @include media-breakpoint-up(lg) {
          padding-bottom: 48%;
        }
      }
      label {
        top: 33%;
        font-size: 14px;
      }
      span {
        font-size: 24px;
        font-weight: 900;
      }
    }
  }
}
