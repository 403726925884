@mixin line-clamp($count) {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  -moz-box-orient: vertical;
  overflow: hidden;
  white-space: inherit;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $count;
}

@mixin fontawesomelist($iconList) {
  // (angle-down:$fa-var-angle-down)
  @each $iconName, $iconVar in $iconList {
    .#{$fa-css-prefix}-#{$iconName} {
      //.fa-angle-down
      &:before {
        content: fa-content($iconVar);
      }
    }
  }
}

@mixin customScroll($width, $radius, $color, $hovercolor: $color, $bgcolor: transparent, $margintop: 0, $marginbottom: 0) {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: $radius;
    background-color: $bgcolor;
  }

  &::-webkit-scrollbar {
    width: $width;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $radius;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $color;
  }
  @include media-breakpoint-up(sm) {
    &:hover::-webkit-scrollbar-thumb {
      background-color: $hovercolor;
    }
  }

  &::-webkit-resizer {
    display: none;
  }

  scrollbar-width: thin;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
}
