.top20_card {
  $self: &;
  display: block;

  .figure {
    position: relative;
  }

  .img-wrap {
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    border-radius: 7px;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    min-height: 216px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    &.img-wrapper {
      padding-bottom: 100%;
      background-size: cover;
    }

    &:before {
      content: "";
      position: absolute;
      background-image: linear-gradient(180deg,
          rgba(0, 0, 0, 0) 0%,
          #000000 100%);
      bottom: 0;
      height: 55%;
      width: 100%;
      z-index: 9;
    }

    .player-button {
      opacity: .75;
      text-align: center;
      border-radius: 50%;
      border: 1px solid #fff;
      padding: 8px;
      width: 42px;
      font-size: 18px;
      margin: auto;
      background-color: rgba($color: #000000, $alpha: .5);
      margin-top: 40%;

      &:hover {
        opacity: 1;
      }

      &.play {
        opacity: 1;
      }

      &.pause {}
    }
  }

  img {
    display: block;
    transition: transform 0.2s ease-out;
    width: 100%;
    will-change: transform;
    opacity: 1;

    @include media-breakpoint-up(lg) {
      border-radius: 7px;
    }
  }

  .caption {
    position: absolute;
    left: 0;
    bottom: -15px;
    width: 100%;
    text-align: center;
    z-index: 999;
    display: block;
    overflow: hidden;
  }

  .title {
    color: $color-white;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }

  .spot {
    color: rgba($color-white, 0.7);
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }

  .number {
    background: $color-cerulean;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
    height: 31px;
    width: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6px auto 0;
    position: relative;
    z-index: 11111;
  }
}

.five-grid {
  height: 216px;
}

@include media-breakpoint-down(sm) {
  .top20-container {
    .five-grid {

      // .figure {
      //   margin-top: 46px;
      // }
      &:first-child {
        flex-basis: 100%;
        max-width: 100%;

        .figure {
          margin-top: 0;
        }

        .caption {
          bottom: -22px;
        }

        .title {
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
        }

        .spot {
          font-size: 14px;
          line-height: 25px;
        }

        .number {
          width: 43px;
          height: 43px;
          font-size: 18px;
        }
      }
    }
  }
}
