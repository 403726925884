.instagram-widget {
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 20px;
  @include media-breakpoint-up(md) {
    padding-bottom: 10px;
  }
  .tag {
    font-size: 14px;
    text-align: center;
    padding: 0 30px;
    @include media-breakpoint-up(md) {
      text-align: left;
      padding: 15px 180px 0 45px;
      flex: 1;
    }
    @include media-breakpoint-up(lg) {
      padding-right: 150px;
    }

    &-title {
      font-size: 18px;
      margin: 0;
    }
    &-description {
      line-height: 25px;
      opacity: 0.8;
    }

    .ui-button {
      font-size: 12px;
      margin: 20px auto 0;
      display: inline-block;
      @include media-breakpoint-up(md) {
        position: absolute;
        right: 20px;
        bottom: 10px;
      }
    }
  }
  .widget-img {
    position: relative;
    text-align: center;
    width: 100%;
    padding-bottom: 100%;
    > span {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-size: cover;
      background-position: center;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 130px;
      margin: 0 auto 6px;
      @include media-breakpoint-up(md) {
        margin: 0 0 0 10px;
      }
    }
    &:before {
      content: '';
      position: absolute;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 80%);
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 9;
    }
    &:after {
      content: '';
      position: absolute;
      background-image: url(../img/instagram-Icon.png);
      bottom: 1px;
      height: 33px;
      width: 33px;
      z-index: 9;
      margin-left: -16px;
      background-repeat: no-repeat;
      @include media-breakpoint-up(md) {
        bottom: 50%;
        margin-bottom: -16px;
        right: -16px;
      }
    }
  }
}

section.about-container  {
  color: $color-white;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;

  @include media-breakpoint-down(md) {
    padding-right: 42px;
    padding-left: 42px;
  }

  @include media-breakpoint-down(sm) {
    padding-right: 30px;
    padding-left: 30px;
  }

  .inline-big-title, h2 {
    font-size: 14px;
    font-weight: 800;
    padding: 10px 0;
    opacity: 1;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  p {
    opacity:.6;
  }

  a{
    color:#0591e2;
    font-size:14px;
    line-height: 25px;
  }
  
  button {
    margin-top: 10px;
    font-size: 12px;
  }
}