.program-container {
  background: #031324;

  @include media-breakpoint-up(lg) {
    padding-top: 18px;
    margin-top: 8rem;
  }

  @include media-breakpoint-down(md) {
    padding: 40px 20px 0;
  }

  .default_card {
    margin-bottom: 20px;
  }
}

.program-element {
  margin: 0 0 50px;
}

.program-section {
  .see-all-card {
    margin-top: 26px;

    @include media-breakpoint-up(lg) {
      margin-top: 35px;
    }

    >span {
      padding: 26% 0;

      @include media-breakpoint-up(lg) {
        line-height: 24px;
      }
    }
  }

  .swiper-navigation-next,
  .swiper-navigation-prev {
    margin-top: -25px;
  }

  .section-title {
    margin-bottom: -32px;
  }
}

.podcast-container {
  background: #031324;

  @include media-breakpoint-up(lg) {
    padding-top: 18px;
  }

  @include media-breakpoint-down(md) {
    padding: 24px 30px 0;
  }

  .default_card {
    margin-bottom: 20px;
  }
}

.podcast-element {
  margin: 0 0 30px;
}

.resume {
  margin-top: 4rem;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  &-return {
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #0591E2;
    margin: 2rem;

    @include media-breakpoint-up(lg) {
      margin: 0 0 2rem 0;
    }

    display: flex;
    align-items: center;

    i {
      margin-right: 1rem;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    figure {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2rem;

      @include media-breakpoint-up(md) {
        align-items: center;
        justify-content: flex-start;
      }

      height: 360px;
      width: 100%;

      img {
        width: 292px;
        height: 360px;
      }

      @include media-breakpoint-up(md) {
        width: 292px;

        img {
          width: 100%;
          height: 100%;
        }
      }

    }

    &-info {

      h3 {
        font-weight: 800;
        font-size: 24px;
        line-height: 38px;
      }

      &-title {
        font-weight: 800;
        font-size: 14px;
        line-height: 22px;
        padding: 1rem 0;
      }

      &-text {
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 19px;
          color: #BEBEBE;
          padding-bottom: 8px;
        }
      }

    }
  }
}

.podcast-other-container {
  background-color: #0E2C46;
  box-shadow: 0 0 0 100vmax #0E2C46;
  clip-path: inset(0 -100vmax);
  padding-top: 24px;

  @include media-breakpoint-down(md) {
    padding: 24px 30px 0;
  }

  .podcast-other-title {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 15px;
  }
}

section.podcast-section {
  margin-bottom: 60px;

  .swiper-pagination-bullet {
    width: 15px !important;
  }

  .swiper-wrapper {
    margin-bottom: 60px;
  }

  .swiper-pagination {
    opacity: 1;
    top: 195px !important;
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: end;
      top: -16px !important;
    }

    @include media-breakpoint-up(lg) {
      opacity: 0;
    }
  }

  .section-link {
    position: absolute;
    float: right;
    right: 30px;
    color: #0591e2;
    margin-top: -52px;
    font-size: 14px;
    font-weight: 700;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  
  @include media-breakpoint-up(lg) {
    margin: auto auto 60px auto;
  }
  .swiper-slide {
    height: 147px !important;
    
    @include media-breakpoint-up(md) {
      height: 187px !important;
    } 

    a, .figure, .img-wrap {
      height: 100%;
    } 
  }
  .section-title {
    text-transform: uppercase;
  }

  .news-card-v2:hover {
    .bg-image {
      transform: none;
      opacity: 0.2;
      &:before {
        content: none;
      }
    }
  }

  .swiper-slide {
    .slide-button {
      display: none;
    }

    &:hover {
      .slide-button {
        display: block;
        border-radius: 50px;
        padding: 10px;
        text-align: center;
        width: 60%;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        border: 1px solid;

        &:hover {
          background-color: #0591E2;
          box-shadow: 0px 1px 23px #0591E2;
          border: 1px solid #0591E2;
        }

        i {
          margin-left: 4px;
        }
      }
    }
  }

  .news-swiper {

    .swiper-container {
      @include media-breakpoint-down(md) {
        padding-top: 16px;
      }
    }
  }
}